import logoMini from '../../images/logo-mini.svg'

import React from 'react'

import data from '../../images/icons/data.svg'
import gear from '../../images/icons/gear.svg'
import rule from '../../images/icons/rule.svg'
import result from '../../images/icons/result.svg'
import diagram from '../../images/icons/diagram.svg'

import screenShoot1 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_01.png'
import screenShoot2 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_02.png'
import screenShoot3 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_03.png'
import screenShoot4 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_04.png'
import screenShoot5 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_05.png'
import screenShoot6 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_06.png'
import screenShoot7 from '../../images/casesScreenshots/KFP/casesScreenshots_KFP_07.png'

import ImageSliderPopup from '../../components/ImageSliderPopup'

import { v4 } from 'uuid'

import logo from '../../images/casesScreenshots/KFP/logo.svg'

const featuresKFP = [
    logo,
    [
        { img: data, text: 'Сводная информация для сдачи фин отчетности на биржу по группе компаний в одном источнике' },
        { img: gear, text: 'Расчет внутригрупповых оборотов и их элеменация в итоговой отчетности' },
        {
            img: diagram,
            text: 'Возможность внесенния консолидирующих операций',
        },
        {
            img: diagram,
            text: 'Возможность перекладывания справочников данных на эталонные корпоративные данные',
        },
    ],
]

function KFP() {
    const screenShots = [screenShoot1, screenShoot2, screenShoot3, screenShoot4, screenShoot5, screenShoot6, screenShoot7]

    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                <img src={logoMini} alt="" style={{ height: '1em' }} /> CasPlan
            </h2>
            <p>
                "CasPlan" представляет собой современное решение для автоматизации и оптимизации бизнес-процессов в компаниях. Этот инструмент обеспечивает
                централизованный контроль и управление различными бизнес-функциями, что в результате повышает производительность и снижает затраты.
            </p>
            <br />
            <br />

            <h3>История клиента:</h3>
            <p>Основным запросом компании было автоматизация отчетности для различных подразделений. CasPlan обладает исключительным набором инструментов, который позволяет не только использовать гибкость Excel, но и обеспечить надежность и сохранность структурированных данных, получаемых напрямую из источников, в которых ведется учет. Данные особенности системы полностью покрыли потребности КФП, и позволили построить множество отчетностей доступных из любой точки мира.</p>
            <br />

            <h3>Сделано с помощью CasPlan</h3>
            <p>
                Реализация проекта привела к внедрению множества моделей данных, сосредоточенных на ключевых финансовых показателях, таких как денежный поток, прибыльность и др. Эти модели обеспечивают целостное видение финансового здоровья компании, способствуя лучшему стратегическому планированию и
                распределению ресурсов.
            </p>

            <p>
                Теперь KazFoodProducts может проводить глубокий финансовый анализ как на макро-, так и на микроуровне, оценивая производительность по всем дочерним компаниям и обеспечивая согласованное управление финансами во всей компании.
            </p>

            <ImageSliderPopup imagesArray={screenShots} />
        </div>
    )
}

export { KFP, featuresKFP }
