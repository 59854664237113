import React from 'react'

import logo from '../../images/logos/samolet.svg'

import data from '../../images/icons/data.svg'
import gear from '../../images/icons/gear.svg'
import rule from '../../images/icons/rule.svg'
import result from '../../images/icons/result.svg'
import diagram from '../../images/icons/diagram.svg'

import logoMini from '../../images/logo-mini.svg'

const featuresSamolet = [
    logo,
    [
        { img: data, text: 'Обеспечены механизмы сверки данных с данными в хранилище данных' },
        { img: gear, text: 'Основная нагрузка по отчетам была перенесена в BI системы' },
        { img: gear, text: 'Возможность по запросу выгружать в автоматическом режиме данные из системы 1С в хранилище данных ' },
    ],
]

function Samolet() {
    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                <img src={logoMini} alt="" style={{ height: '1em' }} /> CasPlan
            </h2>
            <p>
                "CasPlan" представляет собой современное решение для автоматизации и оптимизации бизнес-процессов в компаниях. Этот инструмент обеспечивает
                централизованный контроль и управление различными бизнес-функциями, что в результате повышает производительность и снижает затраты.
            </p>
            <br />

            <h3>История клиента:</h3>
            <p>Основным запросом компании было разгрузить учетную систему 1С БИТ Финанс, содержащую данные о большом количестве юридических лиц.</p>
            <br />

            <h3>Сделано с помощью ETL Модуля выгрузки 1С</h3>
            <p>
                Создана система, позволяющая администраторам по запросу выгружать в автоматическом режиме данные из системы 1С в хранилище данных (DWH) из базы
                1С Предприятие более чем 2 терабайта. Обеспечены механизмы сверки данных с данными в хранилище данных (DWH) После этого основная нагрузка по
                отчетам была перенесена в BI системы.
            </p>
        </div>
    )
}

export { Samolet, featuresSamolet }
