//Analog
import Review from './Review'
import './Analogs.scss'
import xcllogo from '../images/xcllogo.svg'

import logoMini from '../images/logo-mini.svg'

import { FaCheck } from 'react-icons/fa6'

function Analogs() {
    

    return (
        <div className="analogs-container">
            <h2>Сравнение с аналогами</h2>

            <div className="comparisons">
                <div className="comparisons__item">
                    <div className="row">
                        <div></div>
                        <div><img src={logoMini} alt="" style={{ height: '2em', marginRight: '5px' }} /> CasPlan</div>
                        <div>
                            <img src={xcllogo} alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Совместная одновременная работа пользователей</div>
                        <div>
                            <FaCheck />
                        </div>
                        <div>X</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Разграничение прав доступа</div>
                        <div>
                            <FaCheck />
                        </div>
                        <div>X</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Возможность автоматической интеграции</div>
                        <div>
                            <FaCheck />
                        </div>
                        <div>X</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Совместная одновременная работа пользователей</div>
                        <div>
                            <FaCheck />
                        </div>
                        <div>X</div>
                    </div>
                </div>
                <div className="comparisons__item">
                    <div className="row">
                        <div></div>
                        <div><img src={logoMini} alt="" style={{ height: '2em', marginRight: '5px' }} />CasPlan</div>
                        <div style={{ fontSize: '12px', textAlign: 'center' }}>Традиционные системы планирования</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Стоимость внедрения</div>
                        <div>низкие</div>
                        <div>высокие</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Скорость внедрения</div>
                        <div>4-8 нед</div>
                        <div>9-12 мес</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Требования к инфраструктуре клиента</div>
                        <div>низкие</div>
                        <div>высокие</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Размер команды внедрения</div>
                        <div><FaCheck /></div>
                        <div>X</div>
                    </div>
                    <div className="row">
                        <div className="row__item--text">Self-service modelling</div>
                        <div><FaCheck /></div>
                        <div>X</div>
                    </div>
                </div>
            </div>
            {/* <Review /> */}
        </div>
    )
}

export default Analogs
