import './Functional.scss'

import pieChart from '../images/pie-chart.svg'
import Review from './Review'
import SliderButton from './SliderButton'

import Slider from 'react-slick'
import { useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import FormPopupButton from './FormPopupButton'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import logoMini from '../images/logo-mini.svg'

function FunctionalSlider() {
    const [sliderState, setSliderState] = useState([])
    const [currentCategory, setCurrentCategory] = useState('Маркетинг')

    let settings = {
        variableWidth: true,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        infinite: true,
    }

    async function getData(nameOfFile) {
        try {
            const module = await import(`../data/${nameOfFile}.json`)
            const moduleName = nameOfFile

            const result = { [moduleName]: module.default || module }
            return result
        } catch (error) {
            console.error(error)
            return {}
        }
    }

    useEffect(() => {
        async function chooseCategory() {
            const response = await getData('functionalData')

            setSliderState(response.functionalData)
        }

        chooseCategory()
    }, [])

    function onClickHandler(newCategory) {
        setCurrentCategory(newCategory)
    }

    return (
        <div className="functional-block">
            <div className="functional-container">
                <div className="header__header" style={{ marginBottom: '25px' }}>
                    <h1 style={{
                        display: 'flex',
                        alignItems:'center'
                    }}> <img src={logoMini} alt="" style={{ height: '1em', marginRight: '10px' }} />CasPlan</h1>
                    <h3 style={{ fontWeight: 200 }}>
                        Продукт для аналитиков в бизнесе, которые смогут самостоятельно строить системы планирования и отслеживания эффективности
                        enterprise-класса.
                    </h3>
                </div>
                <hr />
                <div className="functional-container__categories">
                    {/* <Slider {...settings} className="categories__buttons-list">
                        {sliderState.map((el) => {
                            return (
                                <SliderButton
                                    name={el.name}
                                    onClickHandler={onClickHandler}
                                    key={uuidv4()}
                                    isActive={el.name === currentCategory ? true : false}
                                />
                            )
                        })}
                    </Slider> */}

                    {sliderState.map((el) => {
                        return (
                            <SliderButton name={el.name} onClickHandler={onClickHandler} key={uuidv4()} isActive={el.name === currentCategory ? true : false} />
                        )
                    })}
                </div>

                <div className="functional-container__content">
                    <div className="content__text">
                        <p>
                            {sliderState.map((el) => {
                                if (el.name === currentCategory) {
                                    return el.text
                                }
                            })}
                        </p>

                        <br />
                        <br />
                        <FormPopupButton />
                    </div>

                    {isBrowser ? <div className="content__illustration"></div> : ''}

                    {/* <img className="content__chart" src={pieChart} alt="chart" /> */}
                </div>
            </div>

            <Review />
        </div>
    )
}

export default FunctionalSlider
