import React, { useRef } from 'react'
import SliderOfClients from './SliderOfClients'
import logoMini from '../images/logo-mini.svg'
import FormPopupButton from './FormPopupButton'
import Form from './Form'
import './Extractor.scss'
import { useEffect, useState, useLayoutEffect } from 'react'

import IClogoGrey from '../images/oneCGrey.svg'

import Slider from 'react-slick'

import checkIcon from '../images/check.svg'

import { CiDatabase } from 'react-icons/ci'
import { FaArrowsTurnRight } from 'react-icons/fa6'
import { MdOutlinePersonOutline, MdVerticalAlignCenter } from 'react-icons/md'
import { TbRefresh } from 'react-icons/tb'
import { FaRegLightbulb } from 'react-icons/fa6'

import icLogo from '../images/oneCGrey.svg'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import arrowDownImage from '../images/arrow-down.svg'

// import logoMini from '../images/logo-mini.svg'

function Extractor() {
    const tableRef = useRef(null)
    const [strokes, setStrokes] = useState([])

    const tableData = [
        {
            name: 'Базы данных и источники 1С',
            data: [
                { id: '1', name: 'Базы данных 1С', to: ['4'] },
                { id: '2', name: 'Excel файлы с фактическими данными', to: ['5'] },
                { id: '3', name: 'Excel файлы с плановыми данными', to: ['6'] },
            ],
        },
        {
            name: 'Модуль ETL',
            data: [
                { id: '4', name: 'Универсальный модуль выгрузки', to: ['7'] },
                { id: '5', name: 'Загрузка данных из Excel в БД', to: ['7'] },
                { id: '6', name: 'Загрузка планов из Excel в БД', to: ['7'] },
            ],
        },
        {
            name: 'Data Warehouse',
            data: [
                { id: '7', name: 'Таблицы с первичными данными', to: ['8'] },
                { id: '8', name: 'Агрегированные данные для отчетности', to: ['9', '10', '11', '12'] },
                { id: '9', name: 'Подготовка витрины данных', to: ['10', '11', '12'] },
            ],
        },
        {
            name: 'CasPlan',
            data: [
                { id: '10', name: 'Дашборды фин. отчетности', to: [] },
                { id: '11', name: 'Универсальный Дашборд', to: [] },
                { id: '12', name: 'Дашборд по продажам', to: [] },
            ],
        },
    ]

    useLayoutEffect(() => {
        const timeoutId = setTimeout(() => {
            const newStrokes = []
            tableData.forEach((column) => {
                column.data.forEach((el) => {
                    if (el.to.length > 0) {
                        const currentElement = document.querySelector(`[data-section="${el.name}"]`)
                        const currentRect = currentElement.getBoundingClientRect()
                        const parentRect = tableRef.current.getBoundingClientRect()
                        let x1 = currentRect.x - parentRect.x + currentRect.width
                        let y1 = currentRect.y - parentRect.y + currentRect.height / 2

                        el.to.forEach((targetId) => {
                            const targetColumn = tableData.find((col) => col.data.some((item) => item.id === targetId))
                            const targetElement = targetColumn.data.find((item) => item.id === targetId)

                            // Проверяем, находятся ли стартовый элемент и элемент, на который он указывает, в одной колонке
                            const startElementColumnIndex = tableData.findIndex((col) => col.data.some((item) => item.id === el.id))
                            const targetElementColumnIndex = tableData.findIndex((col) => col.data.some((item) => item.id === targetId))

                            if (targetElement && startElementColumnIndex === targetElementColumnIndex) {
                                // Если оба элемента находятся в одной колонке, выполняем необходимые действия
                                const targetElementDom = document.querySelector(`[data-section="${targetElement.name}"]`)
                                const targetRect = targetElementDom.getBoundingClientRect()

                                let x2 = targetRect.x - parentRect.x + targetRect.width / 2
                                let y2 = targetRect.y - parentRect.y

                                // Переносим точку начала линии в нижнюю часть стартового элемента
                                let horizontal = targetRect.x - parentRect.x + targetRect.width / 2
                                let vertical = y1 + currentRect.height * 0.5

                                newStrokes.push({ x1: horizontal, y1: vertical, x2, y2 })

                                // console.log('TRUE')
                            } else {
                                if (targetElement) {
                                    const targetElementDom = document.querySelector(`[data-section="${targetElement.name}"]`)
                                    const targetRect = targetElementDom.getBoundingClientRect()
                                    let x2 = targetRect.x - parentRect.x
                                    let y2 = targetRect.y - parentRect.y + targetRect.height / 2

                                    if (x1 !== x2 && y1 !== y2) {
                                        const midX = (x1 + x2) / 2
                                        let midY = y2

                                        if (y1 / y2 > 0.5 && y1 / y2 < 1.0) {
                                            // console.clear()
                                            // console.log('targetId: ', targetId, '', 'delenie:', y1 / y2)

                                            midY = y1
                                            y2 = y1
                                        }

                                        newStrokes.push({ x1, y1, x2: midX, y2: y1 })
                                        newStrokes.push({ x1: midX, y1, x2: midX, y2: midY })
                                        newStrokes.push({ x1: midX, y1: midY, x2, y2: midY })
                                    } else {
                                        newStrokes.push({ x1, y1, x2, y2 })
                                    }
                                }
                            }
                        })
                    }
                })
            })
            setStrokes(newStrokes)
        }, 100)
        return () => clearTimeout(timeoutId)
    }, [])

    const handleGetPosition = (event) => {
        const section = event.target.dataset.section
        if (section) {
            const rect = event.target.getBoundingClientRect()
            const parentRect = tableRef.current.getBoundingClientRect()
            const x = rect.x - parentRect.x // Координата X относительно контейнера extractor-table
            const y = rect.y - parentRect.y // Координата Y относительно контейнера extractor-table
            // console.log(`Section: ${section}, x: ${x}, y: ${y}`)
        }
    }

    function handleHoverColumnTitle(columnIndex) {
        // console.log(columnIndex)

        let header = document.querySelector(`h4.column${columnIndex}`)
        header.classList.add('hover-active')

        // console.log(header)
    }

    function handleHoverColumnTitle(columnIndex, isHovering) {
        let header = document.querySelector(`h4.column${columnIndex}`)
        if (isHovering) {
            header.classList.add('hover-active')
        } else {
            header.classList.remove('hover-active')
        }
    }

    const strokeColor = '#c1c1c1'

    const ourAdvantages = [
        { text: 'Быстрый процесс внедрения за счет готовой и отлаженной инфраструктуры.', img: checkIcon },
        { text: 'Наличие готовых наработок по формам отчетности ускоряет получение результата.', img: checkIcon },
        {
            text: 'Готовая инфраструктура позволяет нам сосредотачиваться на проектировании удобной и информативной отчетности, а не на сборе информации и «выуживании» ее из источников.',
            img: checkIcon,
        },
        { text: 'Знаем учет и говорим с бизнесом на одном языке.', img: checkIcon },
        { text: 'Имеем практический опыт внедрения сложных проектов.', img: checkIcon },
        {
            text: 'Извлекаем преимущества из различных инструментов, таких как Web сервисы, шины данных, хранимые процедуры для MS SQL Server, Power Bi.',
            img: checkIcon,
        },
        { text: 'Обеспечиваем высокую надежность от несанкционированного проникновения.', img: checkIcon },
        { text: 'Разработали эффективную структуру DWH, быстро собирающую управленческую и финансовую отчетность.', img: checkIcon },
        {
            text: 'Опыт реализации самого масштабного проекта по автоматизации сбора отчетности в Казахстане для группы компании «Кипрос», о котором ранее «сломали зубы» многие наши именитые конкуренты и партнеры.',
            img: checkIcon,
        },
    ]

    const settingsSlider = {
        // variableWidth: true,
        slidesToScroll: 3,
        slidesToShow: 3,
        arrows: false,
        infinite: true,

        autoplay: true,
        autoplaySpeed: 5000,

        // fade: true
        // prevArrow: <ButtonArrowSlider className="prevArrow" />,
        // nextArrow: <ButtonArrowSlider className="nextArrow" />,
        // adaptiveHeight: true,
        // centerMode: true
    }

    return (
        <>
            <div className="header">
                <div className="header__inner" style={{ width: '60%' }}>
                    <div className="header__header">
                        <h1>
                            <img src={logoMini} alt="" style={{ height: '.8em' }} /> Модуль выгрузки 1С
                        </h1>
                        <h3 style={{ fontWeight: 200 }}>Готовые универсальные коннекторы ко всем 1С конфигурациям по автоматизации выгрузки информации</h3>
                    </div>

                    <ul className="--rows">
                        <li>
                            <div className="img-point"></div> готовая система по сбору, проверке и дополнению информации и автоматизации работы
                        </li>
                        <li>
                            <div className="img-point"></div> разработка индивидуальных форм отчетности под ВАШИ потребности.
                        </li>
                        <li>
                            <div className="img-point"></div> готовые формы отчетности и дашбордов, которые легко модифицировать
                        </li>
                    </ul>

                    <div className="button-list">
                        <FormPopupButton
                            className="btn--sm"
                            buttonText="Узнать подробнее"
                            formButtonText="Отправить форму"
                            formHeaderText="Хотите знать подробнее?"
                        />
                        {/* <button className="btn btn--white btn--sm">Купить</button> */}
                    </div>
                </div>

                <div className="header-img"></div>
            </div>

            <div className="extractor-table-container">
                <div>
                    <h2 style={{ textAlign: 'center', marginBottom: 10 }}>Как работает?</h2>

                    <div style={{ textAlign: 'center' }} className="tp-thin extractor-table-description">
                        <span className="tp--bold">*ETL</span> (от англ. Extract, Transform, Load — дословно «извлечение, преобразование, загрузка») — один из
                        основных процессов в управлении хранилищами данных, который включает в себя: извлечение данных из внешних источников; их трансформация
                        и очистка, чтобы они соответствовали потребностям бизнес-модели; и загрузка их в хранилище данных.
                    </div>
                </div>

                {isMobile ? (
                    <div>
                        <div className="extractor-mobile-etl">
                            {tableData.map((column, columnIndex) => {
                                // console.log(columnIndex, tableData.length-2)
                                let classNameBackground = ''

                                switch (true) {
                                    case column.name.includes('Модуль ETL'):
                                        classNameBackground = 'extractor-mobile-etl__extractor-block'
                                        break
                                    case column.name.includes('Базы данных и источники 1С'):
                                        classNameBackground = 'extractor-mobile-etl__IC-block'
                                        break
                                    default:
                                        classNameBackground = ''
                                        break
                                }

                                return (
                                    <div className={`extractor-mobile-etl__section ${classNameBackground}`}>
                                        <h4 className={`column${columnIndex}`}>{column.name === 'Модуль ETL' ? <img src={logoMini} alt="" style={{ height: '1.4em' }}  />: ''} {column.name}</h4>

                                        {column.data.map((el) => {
                                            return <div className="extractor-mobile-etl__subsection"> {el.name}</div>
                                        })}

                                        {columnIndex !== tableData.length-1 ? <img src={arrowDownImage} className='extractor-mobile-etl__mobile'></img> : ''}

                                        {column.name.includes('Базы данных и источники 1С') ? <img src={IClogoGrey} style={{opacity: .3}} alt="" srcSet="" className="img-bg" /> : ''}
                                    </div>
                                )
                            })}
                        </div>

                        
                    </div>
                ) : (
                    ''
                )}

                <div className="extractor-table" ref={tableRef}>
                    {tableData.map((column, columnIndex) => {
                        return (
                            <div key={columnIndex} className="extractor-table__section">
                                <h4 className={`column${columnIndex}`}>{column.name}</h4>
                                {column.data.map((el, index) => {
                                    let classNameList = ''

                                    switch (true) {
                                        case el.name.includes('Универсальный модуль выгрузки'):
                                            classNameList = 'extractor-block default'
                                            break
                                        case el.name.includes('Базы данных 1С'):
                                            classNameList = 'IC-block default'
                                            break
                                        default:
                                            classNameList = 'default'
                                            break
                                    }

                                    // console.log(el.name.includes('Базы данных 1С'))

                                    return (
                                        <div
                                            key={index}
                                            className={`extractor-table__subsection ${classNameList}`}
                                            data-section={el.name}
                                            onClick={handleGetPosition}
                                            onMouseOver={() => handleHoverColumnTitle(columnIndex, true)}
                                            onMouseOut={() => handleHoverColumnTitle(columnIndex, false)}
                                        >
                                            {el.name.includes('Базы данных 1С') ? <img src={IClogoGrey} alt="" srcSet="" className="img-bg" /> : ''}
                                            <div className="subsection-name">{el.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <svg width="0" height="0" style={{ position: 'absolute' }}>
                        <marker id="arrow" markerWidth="10" markerHeight="15" refX="8" refY="3" orient="auto" markerUnits="strokeWidth">
                            <path d="M0,0 L0,6 L9,3 z" fill={strokeColor} />
                        </marker>
                    </svg>

                    {strokes.map((el, index) => (
                        <svg
                            key={index}
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
                            className="extractor-table__line"
                        >
                            <line x1={el.x1} y1={el.y1} x2={el.x2} y2={el.y2} stroke={strokeColor} markerEnd={index % 3 === 2 ? 'url(#arrow)' : ''} />
                        </svg>
                    ))}
                </div>
            </div>

            {/* <div className='extractor-height-container'>


            </div> */}

            <div>
                <div className="extractor-ic-container">
                    <h2 style={{ textAlign: 'center' }}>
                        {' '}
                        Универсальные коннекторы <br />
                        для конфигураций <img src={icLogo} alt="" style={{ height: '.8em' }} />
                    </h2>
                    <br />
                    <div className="advantages-regular ">
                        <div className="advantages-regular__item item1">
                            {' '}
                            <h2>Скорость</h2>
                            <p>
                                Фоновая и многопоточная выгрузка обеспечивает выгрузку 1200 проводок в секунду, что позволяет полностью выгружать данные из баз
                                1С в сотни гигабайт (например ERP) за 2 часа. Выгрузка изменений за период выполняется за 5-10 минут для той же базы ERP и не
                                влияет на текущую производительность системы.
                            </p>
                            <br />
                            <h2>Объем </h2>
                            <p>
                                Основной формат обмена через FTP и архивы csv файлов обеспечивает стабильную выгрузку больших объемов данных без высоких
                                требований к скорости и стабильности канала связи и не нагружает сервер DWH в момент выгрузки данных.
                            </p>
                            <br />
                            <h2>Полнота</h2>
                            <p>
                                Выгрузка дополнительных отчетов вместе с данными позволяет проконтролировать полноту и целостность выгруженной информации в
                                центральном модуле обмена.
                            </p>
                            <FaRegLightbulb className="background-image background-image--left" style={{ transform: 'rotate(25deg)' }} />
                        </div>
                        <div className="advantages-regular__item item2">
                            {' '}
                            <h2>Внедрение и эксплутация</h2>
                            <ul className="--rows">
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Уже разработаны почти для всех конфигураций 1С в Казахстане
                                </li>
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Независимая подсистема не влияет на сложность обновления баз и не зависит имеющихся в базе
                                    объектов и доработок
                                </li>
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Возможность обновления модуля выгрузки без необходимости администратору подключаться к
                                    базам
                                </li>
                            </ul>
                            <TbRefresh className="background-image background-image--left" style={{ transform: 'rotate(25deg)' }} />
                        </div>

                        <div className="advantages-regular__item item3">
                            {' '}
                            <h2>Оптимизация</h2>
                            <ul className="--rows">
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Инкрементальная выгрузка только измененной информации.
                                </li>
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Система гарантированной доставки изменений до DWH.
                                </li>
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Защита от повторной выгрузки из копии базы
                                </li>
                                <li className="extractor-ic-li">
                                    {' '}
                                    <div className="img-point"></div> Логирование процесс выгрузки данных. Выгрузка лога ошибок позволяет администратору быстро
                                    понять причину произошедшего сбоя
                                </li>
                            </ul>
                            <TbRefresh className="background-image background-image--left" style={{ transform: 'rotate(25deg)' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="extractor-dwh-container">
                    <div className="dwh-header">
                        <h2 style={{ textAlign: 'center' }}>Data Warehouse</h2>
                        <div style={{ textAlign: 'center' }} className="tp-thin extractor-table-description">
                            <span className="tp--bold">*DWH</span> (англ. Data Warehouse) это предметно-ориентированная информационная база данных, специально
                            разработанная и предназначенная для подготовки отчётов и бизнес-анализа с целью поддержки принятия решений в организации.
                        </div>

                        <br />

                        <div className="tp-thin extractor-table-descriptor" style={{ textAlign: 'center' }}>
                            С точки зрения процесса ETL, архитектуру хранилища данных можно представить в виде трёх компонентов:
                        </div>
                    </div>

                    <div className="dwh-description">
                        <div className="dwh-descriptor__card">
                            <CiDatabase className="dwh-descriptor__card-icon item4" />
                            <br />
                            <h4 className="item5">источник данных</h4>
                            <p className="item6">
                                содержит структурированные данные в виде таблиц, совокупности таблиц или просто файла (данные в котором разделены
                                символами-разделителями);
                            </p>
                        </div>
                        <div className="dwh-descriptor__card">
                            <FaArrowsTurnRight className="dwh-descriptor__card-icon item4" />
                            <br />
                            <h4 className="item5">промежуточная область</h4>
                            <p className="item6">содержит вспомогательные таблицы, создаваемые временно и исключительно для организации процесса выгрузки</p>
                        </div>
                        <div className="dwh-descriptor__card">
                            <MdOutlinePersonOutline className="dwh-descriptor__card-icon item4" />
                            <br />
                            <h4 className="item5">получатель данных</h4>
                            <p className="item6">хранилище данных или база данных, в которую должны быть помещены извлечённые данные</p>
                        </div>
                    </div>
                </div>
                <br />

                <div className="fa-container extractor">
                    <div className="fa-item">
                        <h3>Скорость</h3>
                        <p>Промежуточные таблицы для обеспечения быстрой загрузки данных из полученных сообщений со скоростью до 100 тыс. строк в секунду</p>
                    </div>
                    <div className="fa-item">
                        <h3>Эффективность</h3>
                        <p>
                            Наличие «быстрых» таблиц с заменой длинных «1С-ных» ключей на короткие ID. Позволяет снизить объем выгружаемой информации в Power Bi
                            на 30 %.
                        </p>
                    </div>
                    <div className="fa-item">
                        <h3>Удобство</h3>
                        <p>Отслеживание изменений данных позволяет пересчитывать агрегированные данные только за период в котором произошли изменения</p>
                    </div>
                    <div className="fa-item">
                        <h3>Прозрачность</h3>
                        <p>
                            Прозрачная, документированная и удобная для обработки финансовой информации структура таблиц облегчает работу аналитика при
                            составлении отчетности
                        </p>
                    </div>
                    {/* <div className="fa-item">
                        <h3>Заголовок</h3>
                        <p>Возможность «переоткрытия» периода, когда измененные данные в закрытом периоде возвращаются в период записи</p>
                    </div> */}
                    {/* <div className="fa-item">
                        <h3>Заголовок</h3>
                        <p>
                            Возможность выгружать готовые данные на сервер Power Bi, что сокращает объем выгружаемых данных, время выгрузки данных и их расчета
                            в Power Bi.{' '}
                        </p>
                    </div> */}
                </div>
            </div>

            {/* <h2 style={{ textAlign: 'center' }}>Наши преимущества</h2>
            <div>
                <Slider {...settingsSlider} className="slider-extractor-adv">
                    {ourAdvantages.map((el) => {
                        // console.log(el)

                        return (
                            <div className="slider-extractor-adv__advantage-container">
                                <div className="slider-extractor-adv__image-container">
                                    <img src={el.img} alt="" className="slider-extractor-adv__image" />
                                </div>

                                <div>{el.text}</div>
                            </div>
                        )
                    })}
                </Slider>
            </div> */}

            <Form headerText={'Хотите знать подробнее?'} buttonText={'Отправить форму'} />
            <SliderOfClients />
        </>
    )
}

export default Extractor
