import Slider from 'react-slick'
import './SliderOfClients.scss'

import logo1 from '../images/logos/logo-tinkoff-bank.png'
import logo2 from '../images/logos/samolet.svg'
import logo3 from '../images/logos/Fix_Price.svg'
import logo4 from '../images/logos/Skillbox.png'
import logo5 from '../images/logos/OVK.png'
import logo6 from '../images/logos/Fix_Price.svg'
import logo7 from '../images/logos/tasb.png'
import logo8 from '../images/logos/VEB-RF.png'
import logo9 from '../images/logos/tvel_rosatom.png'
import logo10 from '../images/logos/KFP.svg'

function SliderOfClients() {
    let settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        infinite: true,

        responsive: [
            {
                breakpoint: '700px',
                settings: {
                    slidesToShow: 3
                }
            }
        ]

        // dots: true,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
    }

    return (
        <div className="sliderContainer">
            {/* <h2>Наши клиенты</h2> */}
            <Slider {...settings}>
                <img src={logo10} alt="Logo" />
                <img src={logo1} alt="Logo" />
                <img src={logo2} alt="Logo" />
                <img src={logo3} alt="Logo" />
                <img src={logo4} alt="Logo" />
                <img src={logo5} alt="Logo" />
                <img src={logo6} alt="Logo" />
                <img src={logo7} alt="Logo" />
                <img src={logo8} alt="Logo" />
                <img src={logo9} alt="Logo" />
            </Slider>
        </div>
    )
}

export default SliderOfClients
