import { NavLink } from 'react-router-dom'
import './Menu.scss'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'
import Form from './Form'
import { GiHamburgerMenu } from 'react-icons/gi'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Menu() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const reviewContent = <Form />

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 768) {
                if (window.scrollY > 0) {
                    setIsSticky(true)
                } else {
                    setIsSticky(false)
                }
            } else {
                setIsSticky(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            <div className="menu-fake"></div>
            <nav className={isSticky ? 'sticky-menu' : ''}>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Главная
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/business-cases"
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Бизнес-кейсы
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/functional"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Функционал
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/comparison-anaplan"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Сравнение с Anaplan
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/extractor"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Модуль выгрузки 1С
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/galery"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Галерея
                </NavLink>
                <div
                    className="link link-demo"
                    onClick={(evt) => {
                        setCurrentPopup({ content: reviewContent, isActive: true })
                    }}
                >
                    Хочу демо
                </div>
            </nav>
        </>
    )
}

export default Menu
