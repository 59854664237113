// App.js
import './App.scss'
import './components/typography.scss'
import './components/Popup.scss'

import { useState } from 'react'

// Components
import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import BusinessCases from './components/BusinessCases'
import BusinessCaseItem from './components/BusinessCaseItem'
import Functional from './components/Functional'
import PrivacyPolicy from './components/PrivacyPolicy'
import ComparisonAnaplan from './components/ComparisonAnaplan'
import Sitemap from './components/sitemap.xml'
import NotFound from './components/NotFound'
import Extractor from './components/Extractor'
import Galery from './components/Galery'
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange'
import CasesHex from './components/CasesHex'

// Context
import PopupContext from './context/popupContext'
import { CasesContextProvider } from './context/CasesContext'

// React
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'

function App() {
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [pathname])

    const [currentPopup, setCurrentPopup] = useState({ content: '', isActive: false })

    return (
        <BrowserRouter>
            <ScrollToTopOnPageChange />
            <PopupContext.Provider value={{ currentPopup, setCurrentPopup }}>
                <CasesContextProvider>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<MainLayout />}>
                                <Route index={true} element={<Home />} />

                                <Route path="/business-cases" element={<BusinessCases />} />
                                <Route path="/business-cases/:title" element={<BusinessCaseItem />} />

                                {/* <Route path="/contacts"></Route> */}

                                <Route path="/functional" element={<Functional />}></Route>

                                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                                <Route path="/comparison-anaplan" element={<ComparisonAnaplan />}></Route>
                                <Route path="/extractor" element={<Extractor />}></Route>
                                <Route path="/galery" element={<Galery />}></Route>
                                <Route path="/cases-hex" element={<CasesHex />}></Route>

                                <Route path="/sitemap.xml" element={<Sitemap />} />

                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </div>
                </CasesContextProvider>
            </PopupContext.Provider>
        </BrowserRouter>
    )
}

export default App
