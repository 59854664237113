import './Footer.scss'
import { Link } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa'
import { LiaTelegramPlane } from 'react-icons/lia'
import { FaInstagram } from 'react-icons/fa6'
import { useMediaQuery } from 'react-responsive'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import logoMini from '../images/logo-mini.svg'

function Footer() {
    const isDesktop = useMediaQuery({ minWidth: 600 })

    return (
        <div className="footer-container">
            <div className="footer-container__header">
                <h2 style={{ color: '#fff' }}> <img src={logoMini} alt="" style={{ height: '.8em', marginRight: '5px' }} />CasPlan</h2>
                <p className="tp-thin" style={{ color: '#ebebeb' }}>
                    «Стратегия ваших побед!»
                </p>

                {isBrowser ?  <><br /> <br /></> : ''}

                {/* <div className="footer__social">
                    <a href="https://wa.me/77019301458?text=%D0%A0%D0%B0%D0%B7%20%D0%B4%D0%B2%D0%B0" className="whatsapp-link">
                        <FaWhatsapp style={{ fontSize: '30px' }} /> WhatsApp
                    </a>
                    <a href="https://t.me/WilhelmFriedrichNietzsche" className="telegram-link">
                        <LiaTelegramPlane style={{ fontSize: '30px' }} /> Telegram
                    </a>
                    <a href="https://www.instagram.com/cybernetica.kz" className="instagram-link">
                        <FaInstagram style={{ fontSize: '30px' }} /> Instagram
                    </a>
                </div> */}
            </div>

            <div>
                <div className="footer-container__footer-menu tp-thin">
                    <p className="comment">Полезные ссылки:</p>
                    <Link to="/privacy-policy">Политика конфиденциальности</Link>
                    {/* <Link to="#">Пункт номер два</Link>
                    <Link to="#">Третий пункт</Link> */}
                </div>
                <br />
                <div className="footer-container__phones">
                    <p className="comment">Наши контакты:</p>

                    <a href="tel:+77718000771">
                    +7 771 800 07 71 <span className="tp-thin">- По любым вопросам</span>
                    </a>
                    
                </div>
            </div>

            {/* <div className="footer-container__location">
                <p>Республика Казахстан Г. Алматы ул. Егизбаева 13/54Б офис 309</p>
                <br />

                {isDesktop && (
                    <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3Adaad677b9fd2e457086565620ba33a8f74dc24de5dd3d6d228968e227c31e981&amp;source=constructor"
                                            frameBorder="0"
                        className="footer-container__map"
                    ></iframe>
                )}

                <br />
            </div> */}
        </div>
    )
}

export default Footer
