import React from 'react'
import './CasesHex.scss'

import { useState, useContext } from 'react'

import PopupContext from '../context/popupContext'

import CasesHexDetails from './CasesHexDetails'

const groups = {
    commercialPlaning: {
        color: 'linear-gradient(#72B7DD, #5C98B9)',
    },
    financialPlaning: { color: 'linear-gradient(#72DDD0, #6ABBB1)' },
    'S&OP': { color: 'linear-gradient(#A27BB4, #9166A6)' },
    grey: { color: 'linear-gradient(#BEBEBE, #ABABAB)' },
    HR: { color: 'linear-gradient(#DDBF72, #B9A55C)' },
    empty: { color: 'rgba(0,0,0,0)' },
}

const hexItems = [
    
    {
        text: 'Планирование продаж',
        additionalContent: [
            'Учет дополнительных изменений, предпосылок и ограничений (рынок, маркетинговые активности и др.)',
            'Сравнение с целевыми показателями и внесение корректировок',
            'Учет ввода/вывода продуктов/клиентов',
            'Формирование Плана продаж на детальном уровне',
        ],
        group: 'commercialPlaning',
    },
    {
        text: 'План-факт Продаж',
        additionalContent: ['Сравнение сценариев', 'Отчетность', 'Анализ на различных уровнях гранулярности'],
        group: 'commercialPlaning',
    },
    {
        text: 'Целеполагание и KPI',
        additionalContent: [
            'Внесение утвержденных целевых значений (по компании, брендам, каналам, городам)',
            'Каскадирование целей',
            'Распределение по периодам (сезонность)',
        ],
        group: 'commercialPlaning',
    },
    {
        text: 'Базовая линия и Стат. методы',
        additionalContent: [
            'Загрузка фактических данных',
            'Очистка исторических данных от выбросов',
            'Выбор статистического метода',
            'Формирование Базовой линии продаж',
        ],
        group: 'commercialPlaning',
    },
    {
        text: 'Кадровое планирование',
        additionalContent: [],
        group: 'HR',
    },
    {
        text: 'Развитие и управление талантов',
        additionalContent: [],
        group: 'HR',
    },
    {
        text: 'Планирование ФОТ и мотиваций',
        additionalContent: [],
        group: 'HR',
    },
    {
        text: 'Стратегическое планирование персонала',
        additionalContent: [],
        group: 'HR',
    },
    {
        text: 'Баланс и БДДС',
        additionalContent: ['Формирование БДДС прямым/косвенным методом', 'Оценка движений ДЗ/КЗ'],
        group: 'grey',
    },
    {
        text: 'Оценка коммерческой эффективности',
        additionalContent: ['Оценка прибыльности по клиентам', 'Моделирование контрактных условий'],
        group: 'grey',
    },
    {
        text: 'Промо планирование',
        additionalContent: [
            'Оценка истории промо механик и их эффективности',
            'Формирование промо плана на основании приоритетов и целей, распределение промо бюджета',
            'Промо календарь, оценка и согласование промо активностей',
        ],
        group: 'grey',
    },
    {
        text: 'Ценообразование',
        additionalContent: [
            'Оценка влияния изменений цен',
            'Оценка эластичности',
            'Построение сценариев по ценам',
            'Оценка ценовой стратегии по сегментам, vs конкуренты и др',
        ],
        group: 'grey',
    },
    {
        text: 'Складская Оптимизация',
        additionalContent: ['Моделирование сценариев оптимизации с учетом изменений страхового запаса, уровня сервиса и других KPI'],
        group: 'grey',
    },
    {
        text: 'Оптимизация запасов',
        additionalContent: ['Моделирование сценариев оптимизации с учетом изменений страхового запаса, уровня сервиса и других KPI'],
        group: 'grey',
    },
    {
        text: 'Планирование логист. затрат',
        additionalContent: ['Учет внутренних перемещений', 'Расчет затрат на доставку и хранение'],
        group: 'financialPlaning',
    },
    {
        text: 'Планирование выручки',
        additionalContent: ['Внесение цен и скидок', 'Формирование плана чистой выручки на основании плана продаж'],
        group: 'financialPlaning',
    },
    {
        text: 'Планирование капитал. затрат и амортизации',
        additionalContent: ['Внесение реестра Основных средств', 'Внесение плана закупа основных средств', 'Расчет амортизации с учетом параметров ОС'],
        group: 'financialPlaning',
    },
    {
        text: 'PL и отчетность',
        additionalContent: [
            'Сбор управленческого PL',
            'Формирование PL с дополнительными аналитиками (аллокации)',
            'Сравнение сценариев',
            'План-факт анализ отклонений',
        ],
        group: 'financialPlaning',
    },
    {
        text: 'План-факт PL',
        additionalContent: [
            'Сбор управленческого PL',
            'Формирование PL с дополнительными аналитиками (аллокации)',
            'Сравнение сценариев',
            'План-факт анализ отклонений',
        ],
        group: 'financialPlaning',
    },
    {
        text: 'Планирование операц. затрат',
        additionalContent: [
            'Калькулятор драйверного расчета переменных затрат',
            'Внесение постоянных затрат',
            'Формирование плана операционных затрат по статьям и ЦФО',
        ],
        group: 'financialPlaning',
    },
    {
        text: 'Стратегическое планирование',
        additionalContent: ['Верхнеуровневый долгосрочный план на основании стратегического плана продаж', 'Управленческие корректировки'],
        group: 'financialPlaning',
    },
    {
        text: 'Планирование себестоимости',
        additionalContent: ['Внесение нормативной себестоимости/расчет прямой себестоимости на основании рецептур', 'Распределение косвенных затрат'],
        group: 'financialPlaning',
    },
    {
        text: 'Планирование закупок',
        additionalContent: [
            'Формирование плана потребности сырья на основании плана производства, рецептуры и номенклатура сырья',
            'Внесение информации о минимальных партиях и сроках доставки',
            'Формирование плана закупок с учетом нормативов хранения сырья',
        ],
        group: 'S&OP',
    },
    {
        text: 'Планирование производства ГП',
        additionalContent: [
            'Внесение информации о мощностях, минимальных партиях, планах ремонтов и др.',
            'Формирование плана производства с учетом вводных и ограничений на основании плана отгрузок',
            'Анализ и внесение корректировок по мощностям',
        ],
        group: 'S&OP',
    },
    {
        text: 'Планирование отгрузок',
        additionalContent: [
            'Внесение информации о маршрутах, типах поставки, складских ограничениях',
            'Формирование плана отгрузок на основании плана продаж с учетом нормативов запасов и данных по товарам в пути',
        ],
        group: 'S&OP',
    },
    {
        text: 'План-факт S&OP',
        additionalContent: [
            'Формирование отчетности по планам отгрузок, производства, закупок',
            'Сравнение сценариев',
            'Анализ на различных уровнях гранулярности',
        ],
        group: 'S&OP',
    },
    {
        text: 'Планирование поставок и произ-ва полуфабрикатов',
        additionalContent: [
            'Внесение информации о мощностях, минимальных партиях и др.',
            'Формирование плана производства ПФ с учетом плана производства ГП и дополнительных ограничений',
            'Формирование плана закупок ПФ с учетом нормативов хранения ПФ',
        ],
        group: 'S&OP',
    },
];




function CasesHex() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const [activeIndex, setActiveIndex] = useState(null)

    const handleMouseEnter = (index) => {
        setActiveIndex(index)
    }

    const handleMouseLeave = () => {
        setActiveIndex(null)
    }

    return (
        <div>
            <h2 style={{textAlign: 'center'}}>
                CasPlan – платформа <br /> для интегрированного планирования
            </h2>
            <br />
            {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente tempora mollitia, eveniet illo odit hic ullam consectetur adipisci, cumque
                architecto tempore odio animi corrupti quo minima nisi, voluptatem eum. Consequuntur!
            </p> */}
            <br />
            <h3 style={{textAlign: 'center'}}>Легенда:</h3>
            <div className="legend-container">
                <div className="legend-item" style={{ background: groups.financialPlaning.color }}>
                    Финансовое планирование
                </div>
                <div className="legend-item" style={{ background: groups['HR'].color }}>
                    HR планирование
                </div>
                <div className="legend-item" style={{ background: groups.commercialPlaning.color }}>
                    Коммерческое планирование
                </div>
            </div>
            <br />

            <div className="hex-main">
                <div className="hex-container">
                    {hexItems.map((item, index) => {
                        const isActive = activeIndex === index
                        const inActiveStatus = activeIndex === null ? '' : 'inActive'

                        const pointer = item.group === 'empty' ? 'none' : 'all'

                        return (
                            <div
                                key={index}
                                className={`hex-item ${isActive ? 'active' : inActiveStatus}`}
                                style={{ background: groups[item.group].color, pointerEvents: pointer }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={(evt) => {
                                    if (item.additionalContent.length > 0) {
                                        setCurrentPopup({
                                            content: (
                                                <CasesHexDetails
                                                    additionalContent={item.additionalContent}
                                                    background={groups[item.group].color}
                                                    title={item.text}
                                                    group={item.group}
                                                />
                                            ),
                                            isActive: true,
                                        })
                                    }
                                }}
                            >
                                <div className="hex-text">
                                    <div className="hex-text__inner">{item.text}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CasesHex
