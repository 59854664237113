//Review
import './Review.scss'
import { useContext, useState, useEffect } from 'react'

import PopupContext from '../context/popupContext'
import PopupReview from './PopupReview'
import { getData } from '../utils/getData'

import axios from 'axios'

function Review() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const [reviewState, setReview] = useState([])
    const [thumbnailUrl, setThumbnailUrl] = useState('')
    // console.log(popupContext)

    useEffect(() => {
        async function chooseReview() {
            const response = await getData('reviews')
            const randomIndex = Math.floor(Math.random() * response.reviews.length)

            try {
                const videoUrl = response.reviews[randomIndex].link
                let videoId = videoUrl.split('v=')[1]
                const ampersandPosition = videoId.indexOf('&')
                if (ampersandPosition !== -1) {
                    videoId = videoId.substring(0, ampersandPosition)
                }

                const apiUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=AIzaSyAgBhp2FY5_Rtz85Y5K0Uf04zJeS-B7DBY`
                const newResponse = await axios.get(apiUrl)
                const thumbnailUrl = newResponse.data.items[0].snippet.thumbnails.medium.url

                setThumbnailUrl(thumbnailUrl)
            } catch (error) {
                console.error('Ошибка при получении обложки видео:', error)
            }

            setReview(response.reviews[randomIndex])
        }

        chooseReview()
    }, [])
    // console.log()

    if (reviewState.length === 0) {
        return <div>Loading...</div>
    }

    const reviewContent = <PopupReview link={reviewState.link} />
    // console.log(reviewState, thumbnailUrl)

    return (
        <div className="reviewContainer">
            <div className="reviewContainer__text">
                <h3 style={{ marginBottom: 10 }}>{reviewState.name}</h3>
                <p style={{ fontWeight: 100 }}>{reviewState.text}</p>

                <br />
                <br />
                <i style={{ fontWeight: 100 }}>{reviewState.company}</i>
            </div>
            <div
                className="reviewContainer__preview"
                style={{ backgroundImage: `url(${thumbnailUrl})` }}
                onClick={(evt) => {
                    setCurrentPopup({ content: reviewContent, isActive: true })
                }}
            ></div>
        </div>
    )
}

export default Review
