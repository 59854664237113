//BusinessCasePreviewItem
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'react-router-dom'

function BusinessCasePreviewItem({ title, textPreview, tags, type, client }) {
    // console.log(title, textPreview, tags)



    return (
        <div className="business-case">
            <div className="business-case-preview__header">
                <h3>
                    <Link to={`/business-cases/${encodeURIComponent(title)}`} className="a--black">
                        {client ? client : title}
                    </Link>
                </h3>
                <span className={type === 'demo' ? 'business-case-type business-case-type--demo ' : 'business-case-type business-case-type--real '}> {type}</span>
            </div>
            <div className="textPreview">{textPreview}</div>
            <br />
            <div className="tags-list">
                {tags.map((el) => {
                    return (
                        <div className="tag" key={uuidv4()}>
                            {el}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default BusinessCasePreviewItem
