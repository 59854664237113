async function getData(nameOfFile) {
    try {
        const module = await import(`../data/${nameOfFile}.json`)
        const moduleName = nameOfFile

        const result = { [moduleName]: module.default || module }
        return result
    } catch (error) {
        console.error(error)
        return {}
    }
}

export {getData}