import { FaCheckCircle } from 'react-icons/fa'
import { v4 } from 'uuid'
import { useInView } from 'react-intersection-observer'

import Slider from 'react-slick'
// import { Chart } from 'chart.js'

import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Doughnut, Pie } from 'react-chartjs-2'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Link } from 'react-router-dom'

import * as RealCaseItems from '../data/RealCases/CasesProvider'
import * as RealCaseFeatures from '../data/RealCases/CasesFeaturesProvider'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

Chart.register(ArcElement, Tooltip, Legend, Title)
Chart.register(ChartDataLabels)

function HIWCase({ caseItem }) {
    console.log(caseItem)

    const [ref, inView] = useInView({
        triggerOnce: true,
    })

    // function externalTooltipHandler() {
    //     return <div>'123</div>
    // }

    function getDataForPie(item) {
        // console.log()
        const data = {
            labels: [item.title, ''],
            datasets: [
                {
                    data: [item.value, 100 - item.value],
                    // backgroundColor: ['rgba(54, 162, 235, 0.1)', 'rgba(54, 162, 235, 0.1)'],
                    backgroundColor: (context) => {
                        // console.log(context.chart)

                        if (!context.chart.chartArea) {
                            return
                        }

                        const { ctx, data, chartArea } = context.chart
                        const bg = ctx.createLinearGradient(chartArea.left, 120, chartArea.right, 0)
                        // console.log(chartArea)
                        bg.addColorStop(0, '#157553')
                        bg.addColorStop(1, '#A4C1B7')

                        return bg
                    },
                    borderWidth: [0, 1],
                },
            ],
        }

        return data
    }

    function getOptionsForPie(item) {
        const options = {
            layout: {
                padding: 10,
            },

            animation: {
                animateRotate: true, // Анимация вращения пирога
                // animateScale: true, // Анимация масштабирования
                easing: 'easeOutQuart', // Функция сглаживания (эффект easeOutQuart создает плавное замедление)
                duration: 1500, // Длительность анимации в миллисекундах
            },
            // hover: { mode: null },
            hoverOffset: 60,
            // clip: {left: false, top: false, right: false, bottom: false},
            responsive: true,
            borderRadius: 10,
            offset: [20, 20],
            plugins: {
                tooltip: {
                    enabled: false,
                    // external: externalTooltipHandler,
                },

                legend: {
                    display: false,
                    labels: {
                        color: '#fff',
                    },
                },

                title: {
                    display: false,
                    // fullSize: true,
                    text: item.title,
                    color: '#000',
                    padding: 0,
                    font: {
                        weight: 300,
                        size: 20,
                    },
                },

                datalabels: {
                    formatter: function (value, context) {
                        // console.log(context)
                        if (value === item.value) {
                            const newValue = value + '%'
                            return newValue
                        } else {
                            return ''
                        }
                    },
                    color: 'blue',
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                                size: '20px',
                            },
                            color: 'rgba(255,255,255, .5)',
                        },
                    },
                    textShadowBlur: 50,
                    textShadowColor: 'white',
                    clip: false,
                    anchor: 'end',
                    align: 'left',
                    clamp: false,
                    display: false,
                },
            },
        }

        return options
    }

    let randomEffectIndex
    let randomEffect
    let randomChartData
    let randomChartOptions
    let fullOfFeatures

    let realCaseReview
    let realCaseRandomFeature

    if (caseItem.type === 'demo') {
        randomEffectIndex = Math.floor(Math.random() * caseItem.effects.length)
        randomEffect = caseItem.effects[randomEffectIndex]
        randomChartData = getDataForPie(randomEffect)
        randomChartOptions = getOptionsForPie(randomEffect)
    } else {
        switch (caseItem.title) {
            case 'ТОО «Тасбулат Ойл Корпорейшн»':
                realCaseReview = RealCaseItems.Budget()
                realCaseRandomFeature = RealCaseFeatures.featuresBudget[1][Math.floor(Math.random() * RealCaseFeatures.featuresBudget.length)]
                fullOfFeatures = RealCaseFeatures.featuresBudget[1]
                break
            case 'KazFoodProducts':
                realCaseReview = RealCaseItems.Budget()
                realCaseRandomFeature = RealCaseFeatures.featuresKFP[1][Math.floor(Math.random() * RealCaseFeatures.featuresKFP.length)]
                fullOfFeatures = RealCaseFeatures.featuresKFP[1]
                break
            case '«Тинькофф»':
                realCaseReview = RealCaseItems.Banking()
                realCaseRandomFeature = RealCaseFeatures.featuresBanking[1][Math.floor(Math.random() * RealCaseFeatures.featuresBanking.length)]
                fullOfFeatures = RealCaseFeatures.featuresBanking[1]
                break
            case 'ABCosting':
                realCaseReview = RealCaseItems.ABCosting()
                realCaseRandomFeature = RealCaseFeatures.featuresABCosting[1][Math.floor(Math.random() * RealCaseFeatures.featuresABCosting.length)]
                fullOfFeatures = RealCaseFeatures.featuresABCosting[1]
                break
            case 'Samolet':
                realCaseReview = RealCaseItems.Samolet()
                realCaseRandomFeature = RealCaseFeatures.featuresSamolet[1][Math.floor(Math.random() * RealCaseFeatures.featuresSamolet.length)]
                fullOfFeatures = RealCaseFeatures.featuresSamolet[1]
                break
            case 'Kipros':
                realCaseReview = RealCaseItems.Kipros()
                realCaseRandomFeature = RealCaseFeatures.featuresKipros[1][Math.floor(Math.random() * RealCaseFeatures.featuresKipros.length)]
                fullOfFeatures = RealCaseFeatures.featuresKipros[1]
                break
        }
    }

    // caseItem.type === 'real' ? console.log('realCaseRandomFeature', realCaseRandomFeature, 'realCaseReview', realCaseReview) : console.log('')

    return (
        <div ref={ref} className={`HIW-case-container ${inView ? 'in-view' : ''}`}>
            <div>
                <h2>{caseItem.title}</h2>
                <div style={{ fontWeight: 300 }}>{caseItem.typeOfModel}</div>
            </div>

            {/* <div className="target">
                <h3>Задача проекта:</h3>
                <div>{caseItem.target}</div>
            </div>

            <hr /> */}

            <div className={`HIW-case-container__results ${inView ? 'in-view' : ''}`}>
                <div className={`HIW-case-container__features ${caseItem.type === 'real' ? 'HIW-case-container__features--container-real' : ''}`}>
                    {/* <h3>С помощью CasPlan реализовано:</h3> */}
                    <ul className="HIW-case-container__features-list">
                        {caseItem.type === 'real'
                            ? fullOfFeatures.map((el) => {
                                  if (el.text === realCaseRandomFeature.text) {
                                      return ''
                                  }

                                  return (
                                      <li key={v4()}>
                                          {/* {console.log('123')} */}
                                          <FaCheckCircle className="HIW-case-container__check-mark" />
                                          <p>{el.text}</p>
                                      </li>
                                  )
                              })
                            : caseItem.features.map((el) => (
                                  <li key={v4()}>
                                      <FaCheckCircle className="HIW-case-container__check-mark" />
                                      <p>{el}</p>
                                  </li>
                              ))}
                    </ul>

                    <br />
                    <Link className="btn btn--white btn--sm" to={`/business-cases/${encodeURIComponent(caseItem.title)}`}>
                        Смотреть полный кейс
                    </Link>
                </div>
                {isBrowser ? (
                    <div className="HIW-case-container__effects">
                        {/* <div className="HIW-case-container__pie-chart" key={v4()}> */}
                        {caseItem.type === 'real' ? (
                            ''
                        ) : (
                            <div className="HIW-case-container__effect-title">
                                {randomEffect.title} на <span className="HIW-case-container__effect-percent">{randomEffect.value}%</span>
                            </div>
                        )}

                        {caseItem.type === 'real' ? (
                            <div className="HIW-effect-icon">
                                <img src={realCaseRandomFeature.img} alt="" style={{ width: '200px' }} />
                                <div className="business-case__effect-title">
                                    <div style={{ textTransform: 'uppercase', fontSize: '20px', fontWeight: 700, marginBottom: '10px' }}>После внедрения</div>
                                    {realCaseRandomFeature.text}
                                </div>
                            </div>
                        ) : (
                            <div className="HIW-chart-container">
                                <Doughnut type="doughnut" data={randomChartData} options={randomChartOptions} />
                            </div>
                        )}
                    </div>
                ) : (
                    ''
                )}

                {/* <Link to={`/business-cases/${encodeURIComponent(title)}`} className='a--black' >
                    {title}
                </Link> */}
            </div>
        </div>
    )
}

export default HIWCase
