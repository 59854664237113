import { useContext } from 'react'
import PopupContext from '../context/popupContext'

function Popup() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    return (
        <div className={`popup-container ${currentPopup.isActive ? 'popup-container--active' : ''}`}>
            <div className="current-popup">{currentPopup.content}</div>
            <div className="popup-container__backdrop" onClick={handleBackdropClick}></div>
        </div>
    )
}

export default Popup
