import refresh from '../../images/icons/refresh.svg'
import document from '../../images/icons/document.svg'
import zoom from '../../images/icons/zoom.svg'
import down from '../../images/icons/down.svg'

import data from '../../images/icons/data.svg'
import gear from '../../images/icons/gear.svg'
import rule from '../../images/icons/rule.svg'
import result from '../../images/icons/result.svg'
import diagram from '../../images/icons/diagram.svg'

import { useState, useRef } from 'react'

import Slider from 'react-slick'

import logo from '../../images/logos/Tinkoff_Bank_logo.svg'

const featuresABCosting = [
    logo,
    [
        { img: data, text: 'Функционал загрузки и обработки исходных данных' },
        { img: gear, text: 'Расчет аллокации затрат с учетом правил, включая общие правила' },
        {
            img: diagram,
            text: 'Функционал настройки правил аллокации для каждой статьи/группы затрат - выбор прямые/косвенные, выбор базы аллокации, выбор набора получателей',
        },
    ],
]

function ABCosting() {
    let sliderRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const goto = (index) => {
        sliderRef.slickGoTo(index)
        setActiveIndex(index)
    }

    const CustomDots = ({ onClick, slides }) => (
        <ul className="slick-dots">
            {slides.map((slide, index) => (
                <li key={index} className={index === activeIndex ? 'slick-active' : ''}>
                    {/* <button onClick={() => goto(index)}></button> */}
                    <button></button>
                </li>
            ))}
        </ul>
    )

    const settingsNew = {
        dots: false,
        arrows: false,
        // infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // touchMove: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        // swipe: false,
        initialSlide: activeIndex, // Используйте activeIndex как начальный слайд
        appendDots: (dots) => <CustomDots slides={sliderData} />,
        afterChange: (index) => {
            setActiveIndex(index)
        },


        responsive: [
            {breakpoint: 700,
            settings:{
                slidesToShow: 2
            }
            }
        ]
    }

    const sliderData = [
        {
            text: '1. Функционал загрузки и обработки исходных данных в разрезе статей затрат',
        },
        {
            text: '2. Функционал загрузки и обработки драйверов аллокации (нефинансовые, например, кол-во визитов службы продаж',
        },
        {
            text: '3. Функционал настройки правил аллокации для каждой статьи/группы затрат - выбор прямые/косвенные, выбор базы аллокации, выбор набора получателей (все/ определенные Бизнес-юниты)',
        },
        {
            text: '4. Расчет аллокации затрат с учетом правил, включая общие правила (даже если не выбрано никакое правило для статьи) и поэтапную аллокацию(например распределение Административных затрат пропорционально ранее аллоцированным Операционным затратам)',
        },
        {
            text: '5. Сверка расхождений до/после аллокаций, отчетность полного P&L в разрезе продуктов',
        },
    ]

    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                CasPlan
            </h2>
            <p>
                CasPlan - постоянно развивающаяся платформа Activity-based costing (ABC) , которая регулярно получает новые возможности и дает возможность
                строить все более сложные процессы. Благодаря своей гибкости и высокой производительности CasPlan является отличным инструментом для целей
                Аллокации затрат по методу
            </p>
            <br />
            <br />

            <h3>История клиента:</h3>
            <ul>
                <li>Крупная фармацевтическая компания</li>
                <li>Большое количество продуктов (центров прибыли)</li>
                <li>Большое количество статей затрат, которые как могут быть отнесены на продукты напрямую, так и обеспе¾ивают все/несколько продуктоÃ</li>
                <li>Большой набор драйверов затрат - как операционных, так и финансовых</li>
            </ul>
            <br />
            <h3>
                Задачи, которые могут быть решены при помощи AB costing в <span className="cl--lightblue">CasPlan</span>
            </h3>
            <ul>
                <li>Поддержка принятия операционных решений по управлению затратами на основании драйверов этих затрат</li>
                <li>Поддержка принятия стратегических решений о развитии бизнеса на основании информации о прибыльности ключевых элементов</li>
                <li>Создание инструмента моделирования и прогнозирования эффекта на бизнес в целом от действий по конкретным элементам</li>
                <li>Определение прибыльности различных элементов бизнеса: продуктов, подразделений, клиентов и др.</li>
                <li>Определение направлений для эффективной оптимизации расходов с учетом реального влияния на прибыльность</li>
                <li>Создание системы KPI для департаментов продаж, маркетинга и др. на основании показателей прибыльности</li>
            </ul>

            <br />
            <br />
            <br />

            <h2>
                Как это работает на <span className="cl--lightblue">CasPlan?</span>
            </h2>

            <ul className="--none --icons --features --flex --no-animation">
                <li>
                    <img src={data} alt="" />
                    <p>
                        <span className="tp--bold">1. </span> Определение источников данных, загрузка и обработка
                    </p>
                </li>
                <li>
                    <img src={gear} alt="" />
                    <p>
                        <span className="tp--bold">2. </span> Определение драйверов распределения (базы аллокации)
                    </p>
                </li>
                <li>
                    <img src={rule} alt="" />
                    <p>
                        <span className="tp--bold">3. </span> Определение правил распределения (ограничение набора получателей и др.)
                    </p>
                </li>
                <li>
                    <img src={result} alt="" />
                    <p>
                        <span className="tp--bold">4. </span> Сверка результатов, внесение корректировок
                    </p>
                </li>
                <li>
                    <img src={diagram} alt="" />
                    <p>
                        <span className="tp--bold">5. </span> Аналитика и отчетность
                    </p>
                </li>
            </ul>
            <h3>Что было реализовано в CasPlan:</h3>
            <Slider
                ref={(slider) => {
                    sliderRef = slider
                }}
                {...settingsNew}
                className="slider-AB"
            >
                {sliderData.map((el, index) => {
                    return (
                        <div className="stage" onClick={(event) => goto(index)}>
                            {el.text}
                        </div>
                    )
                })}
            </Slider>

            <br />
            <br />
        </div>
    )
}

export { ABCosting, featuresABCosting }
