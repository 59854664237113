//BusinessCasesHeader
import SliderButton from './SliderButton'
import { v4 as uuidv4 } from 'uuid'

function BusinessCasesHeader({ tags, choosenCategory, setChoosenCategory }) {
    // console.log(choosenCategory)

    function onClickHandler(newCategory) {
        setChoosenCategory(newCategory)
    }

    return (
        <div className="header business-cases-header">
            <div className="header__inner" style={{ width: '75%' }}>
                <div>
                    <h1>Как это работает?</h1>
                    <h3 style={{ fontWeight: 200 }}>Рассмотрим на гипотетических, но очень реальных примерах работу CasPlan. Мы собрали кейсы из самых разных сфер бизнеса и разных подразделений предприятий.</h3>
                </div>
                <hr />

                <div className="tagButtons">
                    {tags.length > 0 &&
                        tags.map((el) => {
                            const isActive = choosenCategory.includes(el)

                            return <SliderButton key={uuidv4()} name={el} onClickHandler={onClickHandler} isActive={isActive} />
                        })}
                </div>
                <p className="tp-thin header__small-text">Выберите интересующие вас разделы</p>

                <div className="header-img"></div>
            </div>
        </div>
    )
}

export default BusinessCasesHeader
