import { useContext } from 'react'
import PopupContext from '../context/popupContext'

import Form from './Form'

function FormPopupButton({ className, buttonText, formButtonText, formHeaderText }) {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    let popupContent

    if ((formHeaderText, formButtonText)) {
        popupContent = <Form headerText={formHeaderText} buttonText={formButtonText} />
    } else {
        popupContent = <Form />
    }

    return (
        <button
            className={`btn btn--light-blue ${className}`}
            onClick={(evt) => {
                evt.preventDefault()
                setCurrentPopup({ content: popupContent, isActive: true })
            }}
        >
            {buttonText? buttonText: "Хочу демо"}
        </button>
    )
}

export default FormPopupButton
