import HIWCase from './HIWCase'
import Slider from 'react-slick'

import { getData } from '../utils/getData'

import { useEffect, useState, setState } from 'react'

import { useCasesContext } from '../context/CasesContext'

import { MdNavigateNext } from 'react-icons/md'

import './HIW.scss'

import { v4 } from 'uuid'

import SliderOfClients from './SliderOfClients'

function HIW() {
    const { casesState, casesTagsState, choosenCategory, choosenCategoryHandler } = useCasesContext()

    const [activeIndexSlide, setActiveIndexSlide] = useState(0)
    const [activeCases, setActiveCases] = useState([])

    useEffect(() => {
        // console.log(activeIndexSlide)

        setActiveCases(
            casesState.filter((el) => {
                // console.log(activeCases)

                if (el.active === true) {
                    return el
                } else {
                    return null
                }
            })
        )
    }, [casesState])

    const ButtonArrowSlider = function (props) {
        const { className, onClick, currentSlider } = props
        const newClasses = className + ' sliderArrowButton'

        return (
            <div onClick={onClick} className={newClasses}>
                <MdNavigateNext />
            </div>
        )
    }

    const DotsHIW = ({ onClick, slides, dots }) => {
        console.log(dots)

        return (
            <ul className="slick-dots">
                {slides.map((slide, index) => {
                    // console.clear()
                    // console.log(activeIndexSlide)

                    return (
                        <li key={v4()} className={index === activeIndexSlide ? 'slick-active' : ''}>
                            <button></button>
                        </li>
                    )
                })}
            </ul>
        )
    }

    const settingsHIW = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,

        prevArrow: <ButtonArrowSlider className="prevArrow" />,
        nextArrow: <ButtonArrowSlider className="nextArrow" />,
        adaptiveHeight: true,
        initialSlide: 0, // Используйте activeIndexSlide как начальный слайд
        // centerMode: true

        beforeChange: (index) => {
            console.log(index + 1)
        },

        responsive: [
            {
                breakpoint: 700,
                settings: {
                    dots: true,
                    // infinite: false,
                    // appendDots: (dots,) => <DotsHIW slides={activeCases} dots={dots} />,
                    // beforeChange: (current, next) => {
                    //     // console.log('Current:', current, 'Next:',next)
                    //     // console.log(event, slick, currentSlide, nextSlide)
                    //     // console.log(currentSlide)

                    //     setTimeout(() => { setActiveIndexSlide(current)}, 300)
                    // },
                    // appendDots: (dots) => (
                    //     <div style={{width: '100%'}}>
                    //         <ul className="slick-dots"> {dots} </ul>
                    //     </div>
                    // ),
                },
            },
        ],
    }

    // console.log(casesState)

    return (
        <div className="HIW-case">
            <h2 style={{ textAlign: 'center' }}>Как это помогает бизнесу?</h2>

            {activeCases.length > 0 ? (
                <Slider {...settingsHIW}>
                    {activeCases.map((el, index) => {
                        // console.log(activeCases.length)
                        // console.log(el.title, index)
                        // debugger

                        return <HIWCase caseItem={el} key={v4()} />
                    })}
                </Slider>
            ) : (
                // <div>123</div>
                <div>Loading...</div>
            )}
        </div>
    )
}

export default HIW
