import React from 'react'

function CasesHexDetails({ additionalContent, background, title, group }) {
    // console.log(background)

    let groupName
    function getGroupName(group) {
        switch (group) {
            case 'financialPlaning':
                groupName = 'Финансовое планирование'
                break
            case 'HR':
                groupName = 'HR планирование'
                break
            case 'commercialPlaning':
                groupName = 'Коммерческое планирование'
                break
            case 'S&OP':
                groupName = 'S&OP'
                break
            default:
                groupName = 'Не входит в состав проекта'
                break
        }
        return groupName
    }

    return (
        <div className="cases-hex-details-container" style={{ background: background }}>
            <h2>{title}</h2>
            <p>{getGroupName(group)}</p>
            <ul>
                {additionalContent.map((el, index) => {
                    return <li key={index}>{el}</li>
                })}
            </ul>
        </div>
    )
}

export default CasesHexDetails
