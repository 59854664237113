//BusinessCases
import React from 'react'
import BusinessCasesHeader from './BusinessCasesHeader'
import BusinessCasePreview from './BusinessCasePreviewList'

import { useCasesContext } from '../context/CasesContext'
import './BusinessCases.scss'

function BusinessCases() {
    const { casesState, casesTagsState, choosenCategory, choosenCategoryHandler } = useCasesContext()

    

    return (
        <div className="home-container ">
            <BusinessCasesHeader tags={casesTagsState} choosenCategory={choosenCategory} setChoosenCategory={choosenCategoryHandler} />
            <BusinessCasePreview cases={casesState} choosenCategory={choosenCategory} />
        </div>
    )
}

export default BusinessCases
