import refresh from '../../images/icons/refresh.svg'
import document from '../../images/icons/document.svg'
import zoom from '../../images/icons/zoom.svg'
import down from '../../images/icons/down.svg'
import logoMini from '../../images/logo-mini.svg'

import { getData } from '../../utils/getData'

import { useState } from 'react'
import axios from 'axios'

import { useEffect } from 'react'

import PopupContext from '../../context/popupContext'

import logo from '../../images/logos/Tinkoff_Bank_logo.svg'

import { useContext } from 'react'

const featuresBanking = [
    logo,
    [
        { img: refresh, text: 'Гибкое сценарное планирование по каждой бизнес-линии' },
        { img: zoom, text: 'Учет особенностей экономики и дополнительных факторов ' },
        { img: document, text: 'Анализ продаж и клиентской базы с расчетом конверсий воронки продаж по бизнес-линиям' },
        { img: down, text: 'Возможности корректировки прогнозов на основе промо-планов или вручную на разных уровнях' },
    ],
]

function Banking() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const [thumbnailUrl, setThumbnailUrl] = useState('')
    const [reviewState, setReview] = useState([])

    let settingsNew = {
        dots: false,
        arrows: false,
        // infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2000,
        // pauseOnHover: true,
        // centerMode: true

        
    }

    useEffect(() => {
        async function loadReview() {
            const response = await getData('reviews')
            const randomIndex = 3

            try {
                const videoUrl = response.reviews[randomIndex].link
                let videoId = videoUrl.split('v=')[1]
                const ampersandPosition = videoId.indexOf('&')
                if (ampersandPosition !== -1) {
                    videoId = videoId.substring(0, ampersandPosition)
                }

                const apiUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=AIzaSyAgBhp2FY5_Rtz85Y5K0Uf04zJeS-B7DBY`
                const newResponse = await axios.get(apiUrl)
                const thumbnailUrl = newResponse.data.items[0].snippet.thumbnails.medium.url

                setThumbnailUrl(thumbnailUrl)
            } catch (error) {
                console.error('Ошибка при получении обложки видео:', error)
            }

            setReview(response.reviews[randomIndex])
        }

        loadReview()
    }, [])

    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                <img src={logoMini} alt="" style={{ height: '1em' }} /> CasPlan
            </h2>
            <p>CasPlan предоставляет среду планирования для решения уникальных задач любой бизнес-функции в режиме реального времени.</p>
            <p>
                CasPlan обеспечивает кроссфункциональное взаимодействие между владельцами и участниками процессов с возможностью сценарного моделирования
                различных финансовых последствий от принятия любого корпоративного решения.
            </p>
            <br />
            <br />

            <h3>История клиента:</h3>
            <p>
                Банк искал инструмент, который был бы гибким, как Excel, но при этом достаточно мощным и легко интегрируемым в IT-инфраструктуру. <br />
                <br />
                Одной из главных задач было улучшение взаимодействия между различными подразделениями при работе с прогнозами, в основном между
                бизнес-функциями, отвечающими за разработку продуктов, и операционными подразделениями, выполняющими их.
            </p>
            <br />

            <h3>Операционное планирование включало в себя: </h3>
            <ul>
                <li>Анализ продаж и клиентской базы с расчетом конверсий воронки продаж по бизнес-линиям [продуктам] и каналам привлечения.</li>
                <li>
                    Построение скользящих прогнозов продаж на подневной основе на основе исторических данных (винтажи) и воронки продаж, включая кросс-продукты.
                </li>
                <li>Возможности корректировки прогнозов на основе промо-планов или вручную на разных уровнях.</li>
                <li>
                    Планирование ключевых показателей операционных подразделений на основе прогнозов продаж и активной клиентской базы: количество операций по
                    типам, прогноз численности сотрудников (включая воронку найма), графики загрузки персонала по часам, планы закупки оборудования и эмиссии
                    карт.
                </li>
            </ul>
            <br />
            <h3>Финансовое планирование включало в себя:</h3>
            <ul>
                <li>Гибкое сценарное планирование по каждой бизнес-линии [продукту] на основе данных операционной модели.</li>
                <li>Учет особенностей экономики и дополнительных факторов в зависимости от специфики каждой бизнес-линии.</li>
                <li>
                    Разработку отдельной модели для планирования страхования с учетом особенностей жизненного цикла продукта и дополнительных
                    нормативных требований.
                </li>
            </ul>
            <br />

            <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/SUvfhJz7mwA?si=ksB6ovnPm9LTyl-m"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen

                className='business-case__video-review'
            ></iframe>

            {/* <br /><br /> */}

            <h3>Итог:</h3>
            <div>
                Результатом стало повышение качества прогнозов, сокращение времени их подготовки и передачи (real-time), а также выявление рисков резких
                изменений на ранних стадиях. Сейчас CasPlan применяют для решения задач:
                <br />
                <br />
                <ul>
                    <li>финансового и операционного планирования</li>
                    <li>прогнозирования операционного результата и прибыли по каждому продукту</li>
                    <li>ведется расписание сотрудников на линиях поддержки</li>
                    <li>развивается блок инфраструктурного планирования</li>
                </ul>
            </div>
        </div>
    )
}

export { Banking, featuresBanking }
