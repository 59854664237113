import refresh from '../../images/icons/refresh.svg'
import document from '../../images/icons/document.svg'
import zoom from '../../images/icons/zoom.svg'
import down from '../../images/icons/down.svg'

// import logo from '../../images/logo.svg'
import oneC from '../../images/oneC.svg'

import { motion } from 'framer-motion'

import { useState, useEffect, useMemo } from 'react'

import screenShoot1 from '../../images/casesScreenshots/Budget/screenShoot01.png'
import screenShoot2 from '../../images/casesScreenshots/Budget/screenShoot02.png'
import screenShoot3 from '../../images/casesScreenshots/Budget/screenShoot03.png'
import screenShoot4 from '../../images/casesScreenshots/Budget/screenShoot04.png'
import screenShoot5 from '../../images/casesScreenshots/Budget/screenShoot05.png'

import logo from '../../images/logos/tasb.png'

import Slider from 'react-slick'

import ImageSliderPopup from '../../components/ImageSliderPopup'

import { v4 } from 'uuid'

const featuresBudget = [
    logo,
    [
        { img: refresh, text: 'единый контур для всех департаментов' },
        { img: zoom, text: 'централизованный мониторинг и повышенная прозрачность' },
        { img: document, text: 'новая улучшенная система отчетности' },
        { img: down, text: 'уменьшены трудозатраты и продолжительность цикла планирования' },
    ],
]

function Budget() {
    // const images = [logo, oneC]

    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    let settingsNew = {
        dots: false,
        arrows: false,
        // infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2000,
        // pauseOnHover: true,
        // centerMode: true
    }

    // useEffect(() => {
    //     // console.log('123')
    //     const interval = setInterval(() => {
    //         setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, [images]);

    // console.log(images)

    const screenShots = [screenShoot1, screenShoot2, screenShoot3, screenShoot4, screenShoot5]

    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                CasPlan
            </h2>
            <p>
                "CasPlan" представляет собой современное решение для автоматизации и оптимизации бизнес-процессов в компаниях. Этот инструмент обеспечивает
                централизованный контроль и управление различными бизнес-функциями, что в результате повышает производительность и снижает затраты.
            </p>
            <br />
            <br />
            <h2>
                Сделано с помощью <span className="cl--lightblue">CasPlan</span>
            </h2>
            <p>
                "CasPlan" предоставил легкую масштабируемость и возможность объединения различных моделей в единый контур для разных департаментов. Платформа
                обеспечила централизованный мониторинг и повысила прозрачность внутренних операций. Улучшенная система отчетности и аналитики помогла в принятии
                стратегических решений.
            </p>
            <p>
                Проект разделялся на функциональные блоки и развивался итеративно. Каждый блок предоставлял готовый инструмент планирования для конкретного
                бизнес-процесса.
            </p>
            <p>
                Пользователи участвовали в тестировании, а обратная связь использовалась для настройки и интеграции процессов. Проект сократил трудозатраты и
                продолжительность цикла планирования, интегрируя процессы в единую систему. Система предоставляет детальные отчеты и аналитику по ходу
                выполнения процессов. Это обеспечивает прозрачность внутренних операций, что может быть полезным при принятии стратегических решений.
            </p>
            <br />
            <ul className="--none --icons --features">
                {featuresBudget.map((el) => {
                    return (
                        <li key={v4()}>
                            <img src={el.img} alt="" />
                            <p>{el.text}</p>
                        </li>
                    )
                })}
            </ul>
            <br />
            <hr />
            <br />
            <br />
            <h2 className="header--with-icon">
                Хранилище данных
                <Slider {...settingsNew} className="slider-icons">
                    <div className="slider-icon slider-icon--logo"></div>
                    <div className="slider-icon slider-icon--oneC"></div>
                </Slider>
                {/* <motion.div
                    className="box"
                    animate={{
                        scale: [1, 1, 1.1, 1.1, 1, 1],
                        rotate: [0, 0, 0, 0, 0],
                        borderRadius: ['0%', '0%', '50%', '50%', '0%'],
                    }}
                    transition={{
                        duration: 3,
                        ease: 'easeInOut',
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 1,
                    }}
                >
                    <motion.img
                        key={images[currentImageIndex]}
                        src={images[currentImageIndex]}
                        alt={`Item ${currentImageIndex + 1}`}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        animate={{
                            opacity: [0, 1, 1, 1, 1, 0, 0],
                        }}
                        transition={{
                            duration: 3,
                            ease: 'easeInOut',
                            times: [0, 0.2, 0.5, 0.8, 1],
                            repeat: Infinity,
                            repeatDelay: 1,
                        }}
                    />
                </motion.div> */}
            </h2>

            <p>
                Командой "Кибернетика" было реализовано Хранилище данных, для выгрузки данных из 1С. Данные из хранилища, на ежедневной основе, загружаются на
                платформу "CasPlan", то есть, при редактировании данных в 1С, они автоматически редактируются на платформе "CasPlan".
            </p>
            <p>
                Планирование бюджета было реализовано похожим, но противоположным способом. Планирование происходит непосредственно внутри платформы "CasPlan" и
                выгружается в 1С с помощью интегрированного сервиса api. То есть, была реализована прямая и обратная интеграция с 1С, что обеспечило единый
                информационный контур.
            </p>
            <br />
            <hr />
            <br />
            <br />
            <h2>Дешборды</h2>
            <p>
                Для каждого подразделения компании, был разработан отдельный дешборд для ввода и редактирования данных. Одна из основных преимуществ платформы
                "CasPlan" – это распределение прав просмотра и редактирования. Бенефициары подразделений имеют доступ только к тем данным, которые относятся к
                их подразделению. "CasPlan" стал не просто инструментом автоматизации, но и ключевым элементом оптимизации процессов планирования.
            </p>

            <ImageSliderPopup imagesArray={screenShots} />

            <br />
            <hr />
            <br />
            <br />
            <h2>Итог</h2>
            <p>
                Интеграция "CasPlan" в "Магнетик Ойл" привела к ряду значительных улучшений в управлении бизнес-процессами. Это позволило автоматизировать
                рутину с ежемесячными отчетами. Графики и показатели меняются автоматически ежемесячно, что экономит массу времени. Также разработана функция
                для автоматической выгрузки отчета в формате "pdf".
            </p>
            <ul>
                <li>Графики и показатели собираются в ежемесячные отчеты</li>
                <li>Отчеты автоматически выгружаются в формате .pdf</li>
            </ul>
            <p>
                Гибкость, прозрачность и масштабируемость этой платформы стали ключевыми факторами успеха, позволяя компании эффективно адаптироваться к
                динамике бизнес-среды и достигать новых высот в планировании и управлении.
            </p>
        </div>
    )
}

export { Budget, featuresBudget }
