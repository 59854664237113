import React, { useContext, useState, useEffect } from 'react'
import PopupContext from '../context/popupContext'



function GaleryPreview({ item }) {
    const { setCurrentPopup } = useContext(PopupContext)
    // console.log(item)

    function setPopupHandler(img){
        const currentImage = <img src={img} className='galery__popup-image'></img>

        setCurrentPopup({ content: currentImage, isActive: true })
    }

   

    return (
        <div className="galery-card ">
            <img
                src={item.img}
                alt=""
                onClick={(evt) => {
                    evt.preventDefault()
                    setPopupHandler(item.img)
                }}
            />
            <h3>{item.name}</h3>
            <p> {item.data}</p>
        </div>
    )
}

export default GaleryPreview
