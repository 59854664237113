function PopupReview({ link }) {
    // console.log(link)

    let videoId = link.split('v=')[1]
    // console.log(videoId)
    const ampersandPosition = videoId.indexOf('&')
    if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition)
    }

    // console.log(videoId)

    return (
        <div className="popup-preview-container">
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        </div>
    )
}

export default PopupReview
