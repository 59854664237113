import React from 'react'

import { IoSpeedometerOutline } from 'react-icons/io5'
import { LiaToolsSolid } from 'react-icons/lia'
import { IoCloudDoneOutline } from 'react-icons/io5'
import { FaCheck } from 'react-icons/fa6'
import { IoAppsOutline } from 'react-icons/io5'

import screenshot1 from '../images/screenshots/Assortimentnoe-planirovanie.jpg'
import screenshot2 from '../images/screenshots/Otslezhivanie-prodazh-po-territorii.jpg'
import screenshot3 from '../images/screenshots/Plan-faktnyj-analiz-sebestoimosti.png'
import screenshot4 from '../images/screenshots/Planirovanie-vyruchki-na-marketplejsah.jpg'
import screenshot5 from '../images/screenshots/Planirovanie-vyruchki-po-tovarnym-liniyam.jpg'
import screenshot6 from '../images/screenshots/Utverzhdenie-plana.png'

import { Link } from 'react-router-dom'

// import ImageSliderPopup from '../../components/ImageSliderPopup'

function FunctionalAdvantages() {
    const screenshots = [screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6]

    return (
        <div>
            <div className="fa-container">
                <div className="fa-item">
                    <IoSpeedometerOutline className="fa-item__icon" />
                    <h3>Скорость расчетов</h3>
                    <p>Перерасчет моделей осуществляется в облаке в реальном времени благодаря технологии in-memory OLAP.</p>
                </div>
                <div className="fa-item">
                    <LiaToolsSolid className="fa-item__icon" />
                    <h3>BI-инструментарий</h3>
                    <p>Реализует систему поддержки принятия решений на основе визуального анализа пользовательских данных и выявления в них трендов.</p>
                </div>
                <div className="fa-item">
                    <IoCloudDoneOutline className="fa-item__icon" />
                    <h3>В облаке или on-premise</h3>
                    <p>Платформу можно развернуть как в облачном сервисе, так и на собственных серверах заказчика.</p>
                </div>
                <div className="fa-item">
                    <FaCheck className="fa-item__icon" />
                    <h3>Низкий порог входа</h3>
                    <p>Система обеспечивает полную свободу моделирования с большим встроенным функционалом и возможностью управлять бизнес-логикой моделей.</p>
                </div>
                <div className="fa-item">
                    <IoAppsOutline className="fa-item__icon" />
                    <h3>Интеграции</h3>
                    <p>
                        Платформа обеспечивает различные инструменты импорта-экспорта данных: как в ручном режиме, так и через специальный функционал
                        интеграционных приложений и коннекторов.
                    </p>
                </div>

                <div className="fa-item fa-item--screenshots-btn" style={{ backgroundImage: `url(${screenshot1})` }}>
                    <Link to={'/galery'}>
                        <h3>Как это выглядит?</h3>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FunctionalAdvantages
