import './Home.scss'

import Header from './Header.js'
import SliderOfClients from './SliderOfClients.js'
import Advantages from './Advantages.js'
import Analogs from './Analogs.js'
import Form from './Form.js'
import HIW from './HIW.js'



function Home() {
    return (
        <div className="home-container">
            <Header />
            
            {/* <SliderOfClients /> */}

            <HIW />
{/* <br /> */}
            <Advantages />

            <Analogs />

            <Form />
        </div>
    )
}

export default Home
