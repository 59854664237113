import React, { createContext, useContext, useEffect, useState } from 'react';

const CasesContext = createContext();

export const CasesContextProvider = ({ children }) => {
  const [casesState, setCasesState] = useState([]);
  const [casesTagsState, setCasesTagsState] = useState([]);
  const [choosenCategory, setChoosenCategory] = useState(['Все']);

  async function getData(nameOfFile) {
    try {
      const module = await import(`../data/${nameOfFile}.json`);
      const moduleName = nameOfFile;

      const result = { [moduleName]: module.default || module };
      return result[nameOfFile];
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  useEffect(() => {
    async function getCases() {
      const response = await getData('business-cases');
      setCasesState(response);

      let tags = [];
      for (let caseItem of response) {
        for (let tag of caseItem.tags) {
          tags.push('Все', tag);
        }
      }
      setCasesTagsState(
        tags.filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
      );
    }

    getCases();
  }, []);

  function choosenCategoryHandler(clickedCategory) {
    if (clickedCategory === 'Все') {
      setChoosenCategory(['Все']);
    } else {
      const isAllSelected = choosenCategory.includes('Все');

      if (isAllSelected) {
        setChoosenCategory([clickedCategory]);
      } else {
        const isCategorySelected = choosenCategory.includes(clickedCategory);

        if (isCategorySelected) {
          const newCategories = choosenCategory.filter((category) => category !== clickedCategory);
          setChoosenCategory(newCategories);
        } else {
          setChoosenCategory([...choosenCategory, clickedCategory]);
        }
      }
    }
  }

  return (
    <CasesContext.Provider value={{ casesState, casesTagsState, choosenCategory, choosenCategoryHandler }}>
      {children}
    </CasesContext.Provider>
  );
};

export const useCasesContext = () => {
  return useContext(CasesContext);
};
