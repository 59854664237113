import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Popup from '../components/Popup'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import PopupContext from '../context/popupContext'

import { useState, useEffect } from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { useContext } from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseOutline } from 'react-icons/io5'

function MainLayout() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const [isVisible, setIsVisible] = useState(false)
    const location = useLocation()

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY > 800 && location.pathname == '/') {
    //             // Установите значение высоты, при котором кнопка появится, и проверьте, что текущий путь не является главной страницей
    //             setIsVisible(true)
    //         } else {
    //             setIsVisible(false)
    //         }

    //         if (location.pathname !== '/') {
    //             setIsVisible(true)
    //         }

    //         // console.log(window.scrollY)
    //     }

    //     window.addEventListener('scroll', handleScroll)

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    // }, [location]) // Подписываемся на изменения пути

    const reviewContent = <Menu />

    // window.onload = function() {
    //     console.log('123')

    //     window.scrollTo(0, 0);
    //   };

    return (
        <>
            <Popup />

            {isMobile && (
                <div
                    className="open-menu-button"
                    onClick={(evt) => {
                        // console.log('123')
                        // console.log(currentPopup)
                        if (!currentPopup.isActive) {
                            setCurrentPopup({ content: reviewContent, isActive: true })
                        } else {
                            handleBackdropClick()
                        }
                    }}
                >
                    {currentPopup.isActive ? <IoCloseOutline /> : <GiHamburgerMenu />}
                </div>
            )}

            {/* {isBrowser && <Menu />} */}

            <div className="container">
                {isBrowser && <Menu />}

                <Outlet />

                <Footer />
            </div>
        </>
    )
}

export default MainLayout
