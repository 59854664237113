import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ScrollToTopOnPageChange = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    handleScrollToTop();

    // console.log(navigate)

    
      
      

    // const unlisten = navigate(({ location }) => {
    //   if (location.action !== 'POP') {
    //     handleScrollToTop();
    //   }

    //   console.log(location)
    // });

    // console.log(navigate())
    
    // return () => {
    // //   unlisten(); // Удалите эту строку, так как useNavigate не возвращает функцию unlisten
    // };
  }, [navigate]);

  return null;
};

export default ScrollToTopOnPageChange;
