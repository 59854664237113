//BusinessCaseItem
import { useParams, useLocation, Link } from 'react-router-dom'
import { useCasesContext } from '../context/CasesContext'
import { v4 as uuidv4 } from 'uuid'

import * as RealCaseItems from '../data/RealCases/CasesProvider'
import * as RealCaseFeatures from '../data/RealCases/CasesFeaturesProvider'

import Form from './Form'
import FormPopupButton from './FormPopupButton'
import BusinessCasePresentation from './BusinessCasePresentation'

import laptopImage from '../images/laptop-second.svg'
import { useEffect } from 'react'

import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Doughnut, Pie } from 'react-chartjs-2'

import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ArcElement, Tooltip, Legend, Title)
Chart.register(ChartDataLabels)

function BusinessCaseItem() {
    // debugger
    // console.log(RealCaseFeatures)

    const { title } = useParams()
    const { casesState } = useCasesContext()

    function getDataForPie(item) {
        // console.log()
        const data = {
            labels: [item.title, ''],
            datasets: [
                {
                    data: [item.value, 100 - item.value],
                    // backgroundColor: ['rgba(54, 162, 235, 0.1)', 'rgba(54, 162, 235, 0.1)'],
                    backgroundColor: (context) => {
                        // console.log(context.chart)

                        if (!context.chart.chartArea) {
                            return
                        }

                        const { ctx, data, chartArea } = context.chart
                        const bg = ctx.createLinearGradient(chartArea.left, 120, chartArea.right, 0)
                        // console.log(chartArea)
                        bg.addColorStop(0, 'rgba(6, 196, 191, 1)')
                        bg.addColorStop(1, '#7d01d3')

                        return bg
                    },
                    borderWidth: [0, 1],
                },
            ],
        }

        return data
    }

    function getOptionsForPie(item) {
        const options = {
            layout: {
                padding: 30,
            },

            animation: {
                animateRotate: true, // Анимация вращения пирога
                // animateScale: true, // Анимация масштабирования
                easing: 'easeOutQuart', // Функция сглаживания (эффект easeOutQuart создает плавное замедление)
                duration: 1500, // Длительность анимации в миллисекундах
            },
            // hover: { mode: null },
            hoverOffset: 60,
            // clip: {left: false, top: false, right: false, bottom: false},
            responsive: true,
            borderRadius: 30,
            offset: [20, 20],
            plugins: {
                tooltip: {
                    enabled: false,
                    // external: externalTooltipHandler,
                },

                legend: {
                    display: false,
                    labels: {
                        color: '#fff',
                    },
                },

                title: {
                    display: false,
                    // fullSize: true,
                    text: item.title,
                    color: '#000',
                    padding: 0,
                    font: {
                        weight: 300,
                        size: 20,
                    },
                },

                datalabels: {
                    formatter: function (value, context) {
                        // console.log(context)
                        if (value === item.value) {
                            const newValue = value + '%'
                            return newValue
                        } else {
                            return ''
                        }
                    },
                    color: 'blue',
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                                size: '20px',
                            },
                            color: 'rgba(255,255,255, .5)',
                        },
                    },
                    textShadowBlur: 50,
                    textShadowColor: 'white',
                    clip: false,
                    anchor: 'end',
                    align: 'left',
                    clamp: false,
                    display: false,
                },
            },
        }

        return options
    }

    if (casesState.length === 0) {
        return <div>Loading...</div>
    }

    if (title === undefined) {
        return <div>Loading...</div>
    }

    const infoAboutCase = casesState.find((el) => title === el.title)
    // console.log(infoAboutCase)
    // console.log(casesState)

    let randomEffectIndex
    let randomEffect
    let randomChartData
    let randomChartOptions

    let realCaseReview
    let realCaseRandomFeature

    let logoOfCompany

    if (infoAboutCase.type != 'real') {
        randomEffectIndex = Math.floor(Math.random() * infoAboutCase.effects.length)
        randomEffect = infoAboutCase.effects[randomEffectIndex]
        randomChartData = getDataForPie(randomEffect)
        randomChartOptions = getOptionsForPie(randomEffect)
    } else {
        switch (infoAboutCase.title) {
            case 'ТОО «Тасбулат Ойл Корпорейшн»':
                realCaseReview = RealCaseItems.Budget()
                realCaseRandomFeature = RealCaseFeatures.featuresBudget[1][Math.floor(Math.random() * RealCaseFeatures.featuresBudget.length)]
                logoOfCompany = RealCaseFeatures.featuresBudget[0]
                break
            case '«Тинькофф»':
                realCaseReview = RealCaseItems.Banking()
                realCaseRandomFeature = RealCaseFeatures.featuresBanking[1][Math.floor(Math.random() * RealCaseFeatures.featuresBanking.length)]
                logoOfCompany = RealCaseFeatures.featuresBanking[0]
                break
            case 'ABCosting':
                realCaseReview = RealCaseItems.ABCosting()
                realCaseRandomFeature = RealCaseFeatures.featuresABCosting[1][Math.floor(Math.random() * RealCaseFeatures.featuresABCosting.length)]
                logoOfCompany = RealCaseFeatures.featuresABCosting[0]
                break
            case 'KazFoodProducts':
                realCaseReview = RealCaseItems.KFP()
                realCaseRandomFeature = RealCaseFeatures.featuresKFP[1][Math.floor(Math.random() * RealCaseFeatures.featuresKFP.length)]
                logoOfCompany = RealCaseFeatures.featuresKFP[0]
                break
            case 'Samolet':
                realCaseReview = RealCaseItems.Samolet()
                realCaseRandomFeature = RealCaseFeatures.featuresSamolet[1][Math.floor(Math.random() * RealCaseFeatures.featuresSamolet.length)]
                logoOfCompany = RealCaseFeatures.featuresSamolet[0]
                break
            case 'Kipros':
                realCaseReview = RealCaseItems.Kipros()
                realCaseRandomFeature = RealCaseFeatures.featuresKipros[1][Math.floor(Math.random() * RealCaseFeatures.featuresKipros.length)]
                logoOfCompany = RealCaseFeatures.featuresKipros[0]
                break
        }
    }

    let randomLive

    return (
        <div className="home-container business-case-home-container">
            <div className="home-container__header header home-container__header--business-case-item">
                <div className="header__inner header__inner--business-case" style={{ alignItems: 'flex-start' }}>
                    <div className="tp-thin">
                        <Link to={'/business-cases/'} className="tp-thin cl--purple">
                            {' '}
                            {'<<< '}назад к списку
                        </Link>
                        <br />
                        <br />
                        {/* <div style={{ marginTop: '5px' }}>{infoAboutCase.descriptor}</div> */}
                        <div className="tags-list">
                            {infoAboutCase.tags.map((el) => {
                                return (
                                    <div className="tag tag--stroke" key={uuidv4()}>
                                        {el}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="business-case__header-text">
                        <div className="business-case__description">
                            {infoAboutCase.type === 'demo' ? (
                                <h2>{infoAboutCase.title}</h2>
                            ) : (
                                <img src={logoOfCompany} alt="" srcSet="" className="business-case__company-logo" loading="lazy" />
                            )}
                            <p>{infoAboutCase.textPreview}</p>
                        </div>
                    </div>

                    <FormPopupButton />
                </div>
                {infoAboutCase.type === 'demo' ? (
                    <div className="business-case__effects HIW-case-container__effects HIW-case-container__effects--sm">
                        <div className="HIW-chart-container">
                            {' '}
                            <Doughnut type="doughnut" data={randomChartData} options={randomChartOptions} />{' '}
                        </div>

                        <div className="business-case__effect-title">
                            <div style={{ textTransform: 'uppercase', fontSize: '20px', fontWeight: 700, marginBottom: '10px' }}>После внедрения</div>
                            {randomEffect.title} на <span className="HIW-case-container__effect-percent">{randomEffect.value}%</span>
                        </div>
                    </div>
                ) : (
                    <div className="business-case__effects HIW-case-container__effects HIW-case-container__effects--sm">
                        <img src={realCaseRandomFeature.img} alt="" style={{ width: '200px' }} loading="lazy" />
                        <div className="business-case__effect-title">
                            <div style={{ textTransform: 'uppercase', fontSize: '20px', fontWeight: 700, marginBottom: '10px' }}>После внедрения</div>
                            {realCaseRandomFeature.text}
                        </div>
                    </div>
                )}

                {/* <img src={laptopImage} alt="" className='business-case__header-img' /> */}
            </div>

            {/* <BusinessCasePresentation /> */}
            {infoAboutCase.type === 'demo' ? (
                <div className="business-case__full-info">
                    <div dangerouslySetInnerHTML={{ __html: infoAboutCase.fullDescription }}></div>
                </div>
            ) : (
                <div className="business-case__full-info business-case__full-info--real">{realCaseReview}</div>
            )}

            <Form />
        </div>
    )
}

export default BusinessCaseItem
