import './Advantages.scss'
import { FaRegLightbulb } from 'react-icons/fa6'
import { MdOutlineShield } from 'react-icons/md'
import { LiaBalanceScaleLeftSolid } from 'react-icons/lia'
import { TbRefresh } from 'react-icons/tb'

import FunctionalAdvantages from './FunctionalAdvantages'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import screenshot1 from '../images/screenshots/Assortimentnoe-planirovanie.jpg'
import screenshot2 from '../images/screenshots/Otslezhivanie-prodazh-po-territorii.jpg'
import screenshot3 from '../images/screenshots/Plan-faktnyj-analiz-sebestoimosti.png'
import screenshot4 from '../images/screenshots/Planirovanie-vyruchki-na-marketplejsah.jpg'
import screenshot5 from '../images/screenshots/Planirovanie-vyruchki-po-tovarnym-liniyam.jpg'
import screenshot6 from '../images/screenshots/Utverzhdenie-plana.png'

import { Link } from 'react-router-dom'

function Advantages() {
    const screenshots = [screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6]

    return (
        <div className="advantages__block">
            <h2>Преимущества</h2>
            <div className="advantages__container">
                <div className="advantages-regular">
                    <div className="advantages-regular__item">
                        <h2>Гибкость</h2>
                        <p>Реализация самых сложных задач планирования</p>
                        <TbRefresh className="background-image background-image--left" style={{ transform: 'rotate(25deg)' }} />
                    </div>
                    <div className="advantages-regular__item">
                        <h2>Безопасность </h2>
                        <p>Надежность корпоративного уровня позволяет уйти из дорогих и небезопасных облаков, установив сервера внутри компании.</p>

                        <MdOutlineShield className="background-image background-image--right" style={{ transform: 'rotate(-25deg)' }} />
                    </div>
                    <div className="advantages-regular__item">
                        <h2>Модели планирования</h2>
                        <p>Готовые к внедрению за 2 месяца и полностью кастомизированные.</p>
                        <FaRegLightbulb className="background-image background-image--left" style={{ transform: 'rotate(25deg)' }} />
                    </div>
                    <div className="advantages-regular__item">
                        <h2>Идеальный баланс</h2>
                        <p>Создан, чтобы заменить громоздкие и неудобные модели в MS Excel и негибкие традиционные системы</p>
                        <LiaBalanceScaleLeftSolid className="background-image background-image--right" style={{ transform: 'rotate(-10deg)' }} />
                    </div>
                </div>
                <div className="advantages-main">
                    <div className="advantages-main__text">
                        <h2>Конструктор функций</h2>
                        <p>
                            В зависимости от целевого пользователя платформа CasPlan предлагает готовый набор шаблонов и функционала для разных отделов
                            предприятий.
                        </p>

                        <br />
                        <br />

                        <ul>
                            <li>
                                <div className="img-point"></div> надёжность корпоративного класса
                            </li>
                            <li>
                                <div className="img-point"></div> серверная установка или облачный SaaS
                            </li>
                            <li>
                                <div className="img-point"></div> гибкость Excel
                            </li>
                        </ul>
                    </div>

                    <div className="advantages-main__images"></div>
                </div>

                {isBrowser ? (
                    <FunctionalAdvantages />
                ) : (
                    <div className="fa-item fa-item--screenshots-btn" style={{ backgroundImage: `url(${screenshot1})` }}>
                        <Link to={'/galery'}>
                            <h3>Как это выглядит?</h3>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Advantages
