import React from 'react'
import screenshot1 from '../images/screenshots/Assortimentnoe-planirovanie.jpg'
import screenshot2 from '../images/screenshots/Otslezhivanie-prodazh-po-territorii.jpg'
import screenshot3 from '../images/screenshots/Plan-faktnyj-analiz-sebestoimosti.png'
import screenshot4 from '../images/screenshots/Planirovanie-vyruchki-na-marketplejsah.jpg'
import screenshot5 from '../images/screenshots/Planirovanie-vyruchki-po-tovarnym-liniyam.jpg'
import screenshot6 from '../images/screenshots/Utverzhdenie-plana.png'

import GaleryPreview from './GaleryPreview'
import logoMini from '../images/logo-mini.svg'

import Form from './Form'

import './Galery.scss'

import { v4 } from 'uuid'

function Galery() {
    const galeryData = [
        {
            name: 'Ассортиментное планирование',
            data: 'Представлена рабочая панель ассортиментного планирования розничной сети дистрибуции одежды для формирования ассортиментной матрицы..',
            img: screenshot1,
        },
        {
            name: 'Территориальный РОП',
            data: 'На дашборде представлена возможность отслеживать плановые и фактические показатели продаж и длительность этапов сделок в каждом отдельно взятом регионе.',
            img: screenshot2,
        },
        {
            name: 'План-фактный анализ себестоимости',
            data: 'Дашборд позволяет увидеть отклонения плановых от фактических затрат как по месяцам, так и по отдельным рабочим центрам.',
            img: screenshot3,
        },
        {
            name: 'Планирование выручки на маркетплейсах',
            data: 'На дашборде отображены данные по планируемой выручке на маркетплейсах исходя из текущего плана продаж, имеющихся данных по продажам с коррекцией на сезонность.',
            img: screenshot4,
        },
        {
            name: 'Планирование выручки по товарным линиям',
            data: 'На дашборде можно увидеть данные о плановой выручке по товарным линиям в сети розничных магазинов одежды как в абсолютном (денежном) выражении, так и в относительном между товарными категориями.',
            img: screenshot5,
        },
        {
            name: 'Утверждение плана',
            data: 'На данном изображении представлена панель управления согласования плана производства на будущие периоды с возможностью расчета в реальном времени при помощи оперативной корректировки ключевых параметров и сравнения нескольких версий планов.',
            img: screenshot6,
        },
    ]

    return (
        <div className='galery-container'>
            <div className="galery-header">
                <h2 > <img src={logoMini} alt="" style={{ height: '1.5em' }} /> Скриншоты и фотографии</h2>

            </div>

            <div className="galery-list">
                {galeryData.map((el, index) => {
                    return <GaleryPreview item={el} key={index}/>
                })}
            </div>

            <Form/>
        </div>
    )
}

export default Galery
