import React from 'react'

import logo from '../../images/logos/kipros.jpeg'

import data from '../../images/icons/data.svg'
import gear from '../../images/icons/gear.svg'
import rule from '../../images/icons/rule.svg'
import result from '../../images/icons/result.svg'
import diagram from '../../images/icons/diagram.svg'

import logoMini from '../../images/logo-mini.svg'

const featuresKipros = [
    logo,
    [
        { img: data, text: 'Создана единая база данных, адаптированная для построения BI отчетности' },
        { img: gear, text: 'Обеспечено ежедневное обновление финансовой информации из более чем 100 источников' },
        { img: gear, text: 'Настроена инфраструктура обслуживания и сверки данных ' },
    ],
]

function Kipros() {
    return (
        <div>
            <h2>
                {/* <img src={logo} alt="" />  */}
                <img src={logoMini} alt="" style={{ height: '1em' }} /> CasPlan
            </h2>
            <p>
                "CasPlan" представляет собой современное решение для автоматизации и оптимизации бизнес-процессов в компаниях. Этот инструмент обеспечивает
                централизованный контроль и управление различными бизнес-функциями, что в результате повышает производительность и снижает затраты.
            </p>
            <br />

            <h3>История клиента:</h3>
            <p>
                Основным запросом компании был сбор в едином источнике информации с более чем 100 источников. В контуре присутствовали все типовые 1С
                конфигурации Казахстана и России, а также решение на базе SAP.Необходимо было обеспечить простоту администрирования, надежность, при минимальном
                вмешательстве в типовые конфигурации.
            </p>
            <br />

            <h3>Сделано с помощью ETL Модуля выгрузки 1С</h3>

            <ul>
                <li>Создана единая база данных, адаптированная для построения BI отчетности</li>
                <li>Обеспечено ежедневное обновление финансовой информации из более чем 100
                источников</li>
                <li>Настроена инфраструктура обслуживания и сверки данных</li>
            </ul>
            
        </div>
    )
}

export { Kipros, featuresKipros }
