//BusinessCasePreviewList
import { v4 as uuidv4 } from 'uuid'
import BusinessCasePreviewItem from './BusinessCasePreviewItem'

function BusinessCasePreviewList({ cases, choosenCategory }) {
    // console.log(cases, choosenCategory)

    return (
        <div className="business-cases__list">
            {choosenCategory.includes('Все')
                ? cases.map((el) => <BusinessCasePreviewItem key={uuidv4()} title={el.title} textPreview={el.textPreview} tags={el.tags} type={el.typeOfModel} client={el.client}/>)
                : cases.map((el) => {
                      if (el.tags.some((tag) => choosenCategory.includes(tag))) {
                          return <BusinessCasePreviewItem key={uuidv4()} title={el.title} textPreview={el.textPreview} tags={el.tags} type={el.typeOfModel} />
                      } else {
                          return null
                      }
                  })}
        </div>
    )
}

export default BusinessCasePreviewList
