import React, { useContext, useState, useEffect } from 'react'
import PopupContext from '../context/popupContext'
import ImageSlider from './ImageSlider'

function ImageSliderPopup({ imagesArray }) {
    const { setCurrentPopup } = useContext(PopupContext)
    const [screenPreviews, setScreenPreviews] = useState([])

    useEffect(() => {
        const fetchImageSizes = async () => {
            const previews = []
            for (const image of imagesArray) {
                const tempImage = new Image()
                tempImage.src = image
                await new Promise((resolve) => (tempImage.onload = resolve))
                if (tempImage.width > tempImage.height) {
                    previews.push(image)
                }
            }
            setScreenPreviews(previews)
        }
        fetchImageSizes()
    }, [imagesArray])

    const popupContent = <ImageSlider imagesArray={screenPreviews} />

    return (
        <div className="screenshot-list">
            {screenPreviews.map((image, index) => {
                if (index < 3) {
                    return (
                        <img
                            key={index}
                            src={image}
                            alt=""
                            onClick={(evt) => {
                                evt.preventDefault()
                                setCurrentPopup({ content: popupContent, isActive: true })
                            }}
                            width="100%"
                            height="30%"
                        />
                    )
                }
            })}
        </div>
    )
}

export default ImageSliderPopup
