import FormPopupButton from './FormPopupButton'
import logoMini from '../images/logo-mini.svg'

import SliderOfClients from './SliderOfClients'

function Header() {
    return (
        <div>
            <div className="header ">
                <div className="header__inner" style={{ width: '60%' }}>
                    <div className="header__header">
                        <h1>
                            <img src={logoMini} alt="" style={{ height: '.8em' }} /> CasPlan
                        </h1>
                        <h3 style={{ fontWeight: 200 }}>
                        Современный продукт, обеспечивающий интегрированный подход к планированию. <br /><br /> Уникальные решения критически важных бизнес-функций: бюджетирование, коммерческое планирование, оптимизация производства, управление цепочками поставок, планирование рабочей силы, аналитику и прогнозирование.

                        </h3>
                    </div>

                    <ul>
                        <li>
                            <div className="img-point"></div> надёжность и мощность корпоративного класса
                        </li>
                        <li>
                            <div className="img-point"></div> серверная установка или облачный SaaS
                        </li>
                        <li>
                            <div className="img-point"></div> гибкость, сопоставимая с Excel
                        </li>
                    </ul>

                    <div className="button-list">
                        <FormPopupButton className="btn--sm" />
                        {/* <button className="btn btn--white btn--sm">Купить</button> */}
                    </div>
                </div>

                <div className="header-img"></div>
            </div>
            <br />
            <SliderOfClients />
        </div>
    )
}

export default Header
