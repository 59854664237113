import React, { useState } from 'react'
import comparison01 from '../data/comparisonAnaplan01.json'
import './comparison.scss'
import { FaCheck, FaTimes } from 'react-icons/fa'

import anaplanLogo from '../images/anaplan-logo.png'
import casPlanLogo from '../images/logo-full.svg'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import Form from './Form'

import { v4 } from 'uuid'

function ComparisonAnaplan() {
    // console.log(comparison01)

    const checkedIcon = (
        <div className="comparison-icon comparison-icon--green">
            <FaCheck />
        </div>
    )
    const uncheckedIcon = (
        <div className="comparison-icon comparison-icon--red">
            <FaTimes />
        </div>
    )

    const upIcon = (
        <div className="comparison-icon comparison-icon--green">
            <FaTimes />
        </div>
    )
    const downIcon = (
        <div className="comparison-icon comparison-icon--red">
            <FaTimes />
        </div>
    )

    return (
        <div>
            <div className="comparison-header">
                <div className='comparison-text'>
                    <h2>Отличия универсальной платформы CasPlan от Anaplan</h2>
                    <p>Детальное описание отличий можно найти в инструкции по пользованию CasPlan и технической документации</p>
                </div>

                <div className="comparison-vs">
                    <img src={casPlanLogo} alt="" className="comparison-row__soft-logo" />
                    vs
                    <img src={anaplanLogo} alt="" className="comparison-row__soft-logo" />
                </div>
            </div>

            {isBrowser ? (
                <div className="comparison-row">
                    <div></div>
                    <div className="comparison-row__soft-logo-container">
                        <img src={casPlanLogo} alt="" className="comparison-row__soft-logo" />
                    </div>
                    <div className="comparison-row__soft-logo-container">
                        <img src={anaplanLogo} alt="" className="comparison-row__soft-logo" />
                    </div>
                </div>
            ) : (
                ''
            )}

            <br />
            <div className="comparison-block comparison-AP-01">
                {comparison01.length > 0
                    ? comparison01.map((el) => {
                          let casPlanIconComponent
                          let anaplanIconComponent

                          switch (el.CasPlan.icon) {
                              case 'checked':
                                  casPlanIconComponent = checkedIcon
                                  break
                              case 'unchecked':
                                  casPlanIconComponent = uncheckedIcon
                                  break
                              default:
                                  casPlanIconComponent = null
                                  break
                          }

                          switch (el.Anaplan.icon) {
                              case 'checked':
                                  anaplanIconComponent = checkedIcon
                                  break
                              case 'unchecked':
                                  anaplanIconComponent = uncheckedIcon
                                  break
                              default:
                                  anaplanIconComponent = null
                                  break
                          }

                          //   console.log(el.CasPlan.icon)
                          return (
                              <div className="comparison-row" key={v4()}>
                                  <div className="comparison-description">{el.description}</div>
                                  <div className="comparison-row__plan-item comparison-row__plan-item--cas-plan">
                                      {casPlanIconComponent}
                                      <div className="comparison-block-description">{el.CasPlan.description}</div>
                                      {isMobile ? <img src={casPlanLogo}></img> : ''}
                                  </div>
                                  <div className="comparison-row__plan-item comparison-row__plan-item--anaplan">
                                      {anaplanIconComponent}
                                      <div className="comparison-block-description">{el.Anaplan.description}</div>
                                      {isMobile ? <img src={anaplanLogo}></img> : ''}
                                  </div>
                              </div>
                          )
                      })
                    : ''}
            </div>
            <br />
            <Form />
        </div>
    )
}

export default ComparisonAnaplan
