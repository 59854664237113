import React from 'react'
import FunctionalSlider from './FunctionalSlider'
import Form from './Form'
import FunctionalHeader from './FunctionalHeader'

import CasesHex from './CasesHex'

import FunctionalAdvantages from './FunctionalAdvantages'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Functional() {
    return (
        <div className="home-container">
                {/* <FunctionalHeader /> */}

                <FunctionalSlider />

               

                {isMobile ? <FunctionalAdvantages /> : ''}

                <CasesHex/>
                <Form />
        </div>
    )
}

export default Functional
