// NotFound.js
import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        // Проверяем наличие статуса ошибки в объекте локации
        const status = location?.state?.status

        if (status === 404) {
            // Код, вызывающий переход на страницу NotFound с установкой статуса ошибки
            // Например, в другом компоненте или в обработчике событий
            navigate('/not-found', { state: { status: 404 } })

            console.log('Это ошибка 404!')
        }

        // Дополнительные действия, если нужно
    }, [location])

    return (
        <div>
            <h2>404 - Страница не найдена</h2>
            {/* Дополнительный контент или ссылки на другие страницы */}
        </div>
    )
}

export default NotFound
