import './Form.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ReactInputMask from 'react-input-mask'
import ReCAPTCHA from 'react-google-recaptcha'

import PopupContext from '../context/popupContext'
import { useContext } from 'react'

function Form({ headerText, buttonText }) {
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const [nameState, setNameState] = useState('')
    const [emailState, setEmailState] = useState('')
    const [phoneState, setPhoneState] = useState('')
    const [nameOrganizationState, setNameOrganizationState] = useState('')
    const [captchaValue, setCaptchaValue] = useState(null)

    const [fullForm, setFullForm] = useState('')

    useEffect(() => {
        const message = `<b>Новая заявка от</b> ${nameState}%0A<b>Номер телефона:</b> ${phoneState}%0A<b>Email:</b> ${emailState}%0A<b>Название организации:</b> ${nameOrganizationState}`
        setFullForm(message)
    }, [nameState, emailState, phoneState, nameOrganizationState])

    const chatID = process.env.REACT_APP_CHAT_ID;
    const botToken = process.env.REACT_APP_BOT_TOKEN;
    // console.log(chatID, botToken)

    const botUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatID}&parse_mode=html&text=${fullForm}`

    function setNameStateHandler(value) {
        setNameState(value)
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        if (!captchaValue) {
            alert('Подтвердите, что вы не робот')
            return
        }
        axios.post(botUrl, nameState).then((response) => console.log(response.status))
        handleBackdropClick()
    }

    return (
        <form className="formDemo" onSubmit={handleSubmit}>
            <div className="formDemo__content">
                <div className="formDemo__text">
                    <h2>{headerText ? headerText : 'Запросить демо'}</h2>
                    <p>Оставьте заявку в форме ниже и мы свяжемся с вами в ближайшее время!</p>
                </div>

                <br />
                <div className="input-list">
                    <input
                        type="text"
                        placeholder="Ваше имя"
                        onChange={(evt) => {
                            setNameStateHandler(evt.target.value)
                        }}
                    />
                    <ReactInputMask
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        onChange={(evt) => {
                            setPhoneState(evt.target.value)
                        }}
                        placeholder="+7 (___) ___-__-__"
                    />
                    <input
                        type="text"
                        placeholder="Название организации"
                        onChange={(evt) => {
                            setNameOrganizationState(evt.target.value)
                        }}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(evt) => {
                            setEmailState(evt.target.value)
                        }}
                    />
                    <ReCAPTCHA sitekey={captchaKey} onChange={(value) => setCaptchaValue(value)} />
                </div>
                <br />
                <button type="submit" className="btn btn--light-blue " style={{border: '1px solid rgba(255, 255, 255, .3)'}}>
                    {buttonText ? buttonText : 'Хочу демо'}
                </button>
            </div>
        </form>
    )
}

export default Form
