import React from 'react'
import Slider from 'react-slick'

import './ImageSlider.scss'

import { v4 } from 'uuid'

import { MdNavigateNext } from 'react-icons/md'

function ImageSlider({ imagesArray }) {
    const ButtonArrowSlider = function (props) {
        const { className, onClick, currentSlider } = props
        const newClasses = className + ' sliderArrowButton'

        return (
            <div onClick={onClick} className={newClasses}>
                <MdNavigateNext />
            </div>
        )
    }

   

    let settingsForImageSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
      
        arrows: true,
        infinite: true,

        dots: true,
        adaptiveHeight: true,

        prevArrow: <ButtonArrowSlider className="prevArrow" />,
        nextArrow: <ButtonArrowSlider className="nextArrow" />,

        
    }

    console.log(imagesArray)

    return (
        <div className="sliderImagesContainer">
            <Slider {...settingsForImageSlider}>
                {imagesArray.map((el) => {
                    return <img src={el} alt="" srcSet="" key={v4()}/>
                })}
            </Slider>
        </div>
    )
}

export default ImageSlider
